import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import LoginSlice from "./Slice/auth/Loginslice";
// import AccountSlice from "./Slice/auth/AccountSlice";
// import FeedListingSlice from "./Slice/FeedSlice";
import FeedListingSlice from "./pages/Modern/Slice/FeedSlice";
// import TicketSlice from "./Slice/auth/TicketSlice";
// import VendorRegisterSlice from "./Slice/auth/VerifyVendorAccount";
// import ExploreSlice from "./Slice/auth/ExploreSlice";
// import CreateSlice from "./Slice/auth/CreateSlice";
// import AsyncStorage from "@react-native-async-storage/async-storage";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";
import { combineReducers } from "redux";
// import SocialAuthSlice from "./Slice/auth/SocialAuthSlice";
// import FollowingSlice from "./Slice/auth/FollowingSlice";
// import CreatorFollowSlice from "./Slice/auth/CreatorFollowSlice";

const reducers = combineReducers({
  //   LoginSlice: LoginSlice,
  //   AccountSlice: AccountSlice,
  FeedListingSlice: FeedListingSlice,
  //   TicketSlice: TicketSlice,
  //   ExploreSlice: ExploreSlice,
  //   VendorRegisterSlice: VendorRegisterSlice,
  //   CreateSlice: CreateSlice,
  //   SocialAuthSlice: SocialAuthSlice,
  //   FollowingSlice: FollowingSlice,
  //   CreatorFollowSlice: CreatorFollowSlice,
});

const persistConfig = {
  key: "root",
  storage,
  //   blacklist: ["RejectTripSlice", "GetAllDriverTripsSlice", "logoutSlice"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  // middleware option needs to be provided for avoiding the error. ref: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serializableCheck: {
      //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      // },

      serializableCheck: false,
      immutableCheck: false,
    }),
});

export const persistor = persistStore(store);

import React, {useEffect} from 'react';
// import QRCode from 'qrcode.react';
import AppStore from "../../assets/appstore_white.png"
import Google from "../../assets/googleplay_white.png"
import Logo from "../../Images/logo.png";
import { Link } from 'react-router-dom';
import Footer from './Footer';
import rightside from "../../Images/rightside.png";
import leftside from "../../Images/leftside.png";
import Header from './Header';

function PageNotFound() {
  
    return (
        <>

    <div className="w-full h-screen flex flex-col items-center justify-center relative">
    <img src={leftside} alt="" className="image left" />
    <img src={rightside} alt="" className="image right" />

   <Header/>

    <div className="flex flex-col items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      

        <div className="flex flex-col items-center md:w-2/3 mt-4">
            <h1 className="text-xl md:text-3xl font-bold text-white tracking-wide">
                Page Not Found!!!
            </h1>
            <br/>
            <br/>
         
        </div>

      
    </div>
</div>
   <div>
                    
                    <Footer />
                    </div>
                    </>


    );
}

export default PageNotFound;

import React, { useEffect, useState } from 'react';
import { getAccessToken, getUsername } from '../../Authentication/tokenStorage';
import './Profile.css';
import CloseIcon from '@mui/icons-material/Close';

const ProfileCard = () => {
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [about, setAbout] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null); // State for previewing selected image
  const [savingProfile, setSavingProfile] = useState(false); // State for saving profile loading effect

  // Function to fetch profile data
  const fetchProfileData = async () => {
    const token = getAccessToken();
    const username = getUsername();

    if (!token || !username) {
      console.error('Token or username not found');
      return;
    }

    console.log('Access Token:', token);
    console.log('Username:', username);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://backend.popat.io/api/users/${username}/`, requestOptions);
      const result = await response.json();
      console.log('Profile Data:', result);
      setProfileData(result);

      // Log profile ID and user for verification
      console.log('Profile ID:', result.profile.id);
      console.log('User:', result.profile.user);
    } catch (error) {
      console.error('Error fetching profile data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []); // Fetch profile data on component mount

  const handleEditModalOpen = () => {
    setFirstName(profileData.profile.first_name);
    setLastName(profileData.profile.last_name);
    setAbout(profileData.profile.bio);
    setPreviewImage(profileData.profile.image); // Set initial preview image
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };

  const handleAboutChange = (event) => {
    setAbout(event.target.value);
  };

  const handleImageChange = (event) => {
    // Handle file upload here
    const file = event.target.files[0];
    setImageFile(file);

    // Preview the selected image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result); // Update preview image state with selected image
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {
    setSavingProfile(true); // Show loading effect

    // Prepare form data for PATCH request
    const formdata = new FormData();
    formdata.append("user", profileData.profile.user); // Automatically include user from profileData
    formdata.append("first_name", firstName);
    formdata.append("last_name", lastName);
    formdata.append("bio", about);
    if (imageFile) {
      formdata.append("image", imageFile);
    }

    const token = getAccessToken();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: formdata,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://backend.popat.io/api/users/update/${profileData.profile.id}/`, requestOptions);
      const result = await response.text();
      console.log('Profile update successful:', result);

      // Reload profile data after successful update
      fetchProfileData();

      // Reset modal state
      setEditModalOpen(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      // Handle error as needed
    } finally {
      setSavingProfile(false); // Hide loading effect
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!profileData) {
    return <div>Error loading profile data.</div>;
  }

  // Check if image and bio are null or empty, then set defaults
  const profileImage = profileData.profile.image || "https://via.placeholder.com/150";
  const bioText = profileData.profile.bio || "No bio yet";

  return (
    <div className="w-full flex items-center justify-between">
      <div className='flex items-center gap-8 w-1/2'>
        <div className="">
          <img src={profileImage} alt="Profile" className='w-40 h-40 rounded-full' />
        </div>
        <div className='flex flex-col gap-1 text-gray-500'>
          <p className='text-black font-semibold text-xl'>
          {profileData.profile.first_name} {profileData.profile.last_name}
          </p>

          <p>
          @{profileData.profile.username}
          </p>

          <div className="flex items-center gap-2">
            <div className="followers">
              <span className="number">{profileData.profile.followers} </span>
              <span className="label">Followers</span>
            </div>
            <div>
              <h1>|</h1>
            </div>
            <div className="followings">
              <span className="number mr-1">{profileData.profile.following}</span>
              <span className="label">Following</span>
            </div>
          </div>

          <p className="bio">{bioText}</p>
          
        </div>
      </div>

      <button className="px-6 py-3 bg-gray-500 font-medium text-white rounded-full" 
      onClick={handleEditModalOpen}>Edit Profile</button>

      {/* Edit Profile Modal */}
      {editModalOpen && (
        <div className=" fixed top-1/2 left-1/2 w-1/3 transform 
        -translate-x-1/2 -translate-y-1/2 bg-[#08A67B] p-6 rounded-lg shadow-lg flex flex-col items-center">
          <div className='flex w-full items-center justify-between text-white'>
            <h2>Edit Profile</h2>
            <button onClick={handleEditModalClose}><CloseIcon /></button>
          </div>

          <div className="mt-4 flex flex-col gap-1 items-center ">
            <label className='text-sm text-white'>Select new profile image</label>
            <div className="flex items-center mt-2">
              <img
                src={previewImage || profileImage}
                alt="Profile"
                className="w-32 h-32 rounded-full cursor-pointer"
                onClick={() => document.getElementById('fileInput').click()}
              />
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                className="hidden"
                onChange={handleImageChange}
              />
            </div>
          </div>

          <div className="mt-4 flex flex-col gap-2 w-full">
            <label className='text-white'>First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={handleFirstNameChange}
              className='w-full border-gray-500 border p-3 rounded outline-none'
            />
          </div>

          <div className="mt-4 flex flex-col gap-2 w-full">
            <label className='text-white'>Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={handleLastNameChange}
              className='w-full border-gray-500 border p-3 rounded outline-none'
            />
          </div>

          <div className="mt-4 flex flex-col gap-2 w-full">
            <label className='text-white'>About:</label>
            <textarea
              value={about}
              onChange={handleAboutChange}
              className='w-full border-gray-500 border p-3 rounded outline-none'
            />
          </div>

          <div className="mt-4">
            <button onClick={handleSaveChanges} className='bg-white px-6 py-3 rounded-full text-[#08A67B]'>
              Update Profile  
            </button>
            {savingProfile && <p className="text-white mt-2">Updating profile...</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileCard;

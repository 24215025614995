import React, { useState } from 'react';
import Logo from "../../Images/logo.png";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="fixed top-4 left-20 right-4 flex items-center justify-between px-4 py-2 bg-transparent z-10">
            <Link to="/"><img src={Logo} alt="" style={{ width: "120px", height: "auto" }} /></Link> 
            {/* Display menu button on mobile */}
            <div className="lg:hidden">
                <button onClick={toggleMenu} className="text-white bg-transparent border border-white px-4 py-2 rounded hover:bg-white hover:text-green-500 transition duration-300">
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
            {/* Normal menu for larger screens */}
            <div className="hidden lg:flex gap-2">
                <Link to="/get-started">
                    <button className="text-white bg-transparent border border-white px-4 py-2 rounded hover:bg-white hover:text-green-500 transition duration-300">
                        Get Started
                    </button>
                </Link>
                <Link to="/about">
                    <button className="text-white bg-transparent border border-white px-4 py-2 rounded hover:bg-white hover:text-green-500 transition duration-300">
                        About Us
                    </button>
                </Link>
                <Link to="/terms">
                    <button className="text-white bg-transparent border border-white px-4 py-2 rounded hover:bg-white hover:text-green-500 transition duration-300">
                        Terms
                    </button>
                </Link>
                <Link to="/contact">
                    <button className="text-white bg-transparent border border-white px-4 py-2 rounded hover:bg-white hover:text-green-500 transition duration-300">
                        Contact Us
                    </button>
                </Link>
            </div>
            {/* Navigation Drawer/Slider for mobile */}
            {menuOpen && (
                <div className="fixed top-0 right-0 bottom-0 bg-black w-64 p-4 shadow-md z-20">
                    <button onClick={toggleMenu} className="text-gray-200 text-lg absolute top-4 right-4">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div className="flex flex-col gap-2 mt-8">
                        <Link to="/get-started">
                            <button className="text-gray-200 bg-transparent px-4 py-2 rounded hover:bg-gray-700 hover:text-white transition duration-300">
                                Get Started
                            </button>
                        </Link>
                        <Link to="/about">
                            <button className="text-gray-200 bg-transparent px-4 py-2 rounded hover:bg-gray-700 hover:text-white transition duration-300">
                                About Us
                            </button>
                        </Link>
                        <Link to="/terms">
                            <button className="text-gray-200 bg-transparent px-4 py-2 rounded hover:bg-gray-700 hover:text-white transition duration-300">
                                Terms
                            </button>
                        </Link>
                        <Link to="/contact">
                            <button className="text-gray-200 bg-transparent px-4 py-2 rounded hover:bg-gray-700 hover:text-white transition duration-300">
                                Contact Us
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Header;

import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Stepper from "@mui/joy/Stepper";
import Step, { stepClasses } from "@mui/joy/Step";
import StepIndicator, { stepIndicatorClasses } from "@mui/joy/StepIndicator";
import Typography from "@mui/joy/Typography";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Button from "@mui/joy/Button";
import Textarea from "@mui/joy/Textarea";
import Box from "@mui/joy/Box";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";

const DragAndDropBox = () => {
  return (
    <Box
      sx={{
        width: 400,
        height: 400,
        border: "2px dashed #ccc",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: 2,
      }}
    >
      Drag & Drop
    </Box>
  );
};

const BigStepper = ({ activeStep, setActiveStep }) => {
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <Stepper
        activeStep={activeStep}
        sx={{
          width: "100%",
          [`& .${stepClasses.root}`]: {
            flexDirection: "column-reverse",
            "&::after": {
              top: "unset",
              bottom:
                "calc(var(--StepIndicator-size) / 2 - var(--Step-connectorThickness) / 2)",
              width: "1px",
            },
          },
          [`& .${stepClasses.completed}::after`]: {
            bgcolor: "#08A67B",
          },
          [`& .${stepClasses.active} .${stepIndicatorClasses.root}`]: {
            borderColor: "#08A67B",
          },
          [`& .${stepClasses.root}:has(+ .${stepClasses.active})::after`]: {
            color: "#08A67B",
            backgroundColor: "transparent",
            backgroundImage:
              "radial-gradient(currentColor 2px, transparent 2px)",
            backgroundSize: "7px 7px",
            backgroundPosition: "center left",
          },
          [`& .${stepClasses.disabled} *`]: {
            color: "neutral.plainDisabledColor",
          },
        }}
      >
        <Step
          completed={activeStep > 0}
          orientation="vertical"
          indicator={
            <StepIndicator variant="solid" color="success">
              {activeStep > 0 ? <CheckRoundedIcon /> : "01"}
            </StepIndicator>
          }
        >
          <Box>
            <Typography level="h4" fontWeight="xl">
              Basic
            </Typography>
          </Box>
        </Step>
        <Step
          completed={activeStep > 1}
          orientation="vertical"
          indicator={
            <StepIndicator variant="solid" color="primary">
              {activeStep > 1 ? <CheckRoundedIcon /> : "02"}
            </StepIndicator>
          }
        >
          <Box>
            <Typography level="h4" fontWeight="xl">
              Date & Time
            </Typography>
          </Box>
        </Step>
        <Step
          completed={activeStep > 2}
          orientation="vertical"
          indicator={
            <StepIndicator variant="solid" color="primary">
              {activeStep > 2 ? <CheckRoundedIcon /> : "03"}
            </StepIndicator>
          }
        >
          <Box>
            <Typography level="h4" fontWeight="xl">
              Pricing
            </Typography>
          </Box>
        </Step>
        <Step
          completed={activeStep > 3}
          orientation="vertical"
          indicator={
            <StepIndicator variant="solid" color="primary">
              {activeStep > 3 ? <CheckRoundedIcon /> : "04"}
            </StepIndicator>
          }
        >
          <Box>
            <Typography level="h4" fontWeight="xl">
              Classification
            </Typography>
          </Box>
        </Step>
      </Stepper>
    </>
  );
};

const BasicStep = () => (
  <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
    <DragAndDropBox />
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Textarea placeholder="Title" />
      <Textarea placeholder="Caption" minRows={3} />
      <Textarea placeholder="Venue" />
      <Textarea placeholder="Additional Information" />
      <Textarea placeholder="Extra" />
    </Box>
  </Box>
);

const DateAndTimeStep = () => (
  <RadioGroup orientation="vertical" sx={{ mt: 2 }}>
    <Radio value="one-day" label="One-Day Event" />
    <Radio value="multi-day" label="Multi-Day Event" />
    <Radio value="recurring" label="Recurring" />
  </RadioGroup>
);

const PricingStep = () => (
  <RadioGroup orientation="vertical" sx={{ mt: 2 }}>
    <Radio value="free-entry" label="Free Entry" />
    <Radio value="paid-entry" label="Paid Entry" />
    <Radio value="free-private" label="Free and Private" />
    <Radio value="paid-private" label="Paid and Private" />
  </RadioGroup>
);

const ClassificationStep = () => (
  <RadioGroup orientation="vertical" sx={{ mt: 2 }}>
    <Radio value="social" label="Social" />
    <Radio value="corporate" label="Corporate" />
  </RadioGroup>
);

function Create() {
  const [activeStep, setActiveStep] = useState(0);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <BasicStep />;
      case 1:
        return <DateAndTimeStep />;
      case 2:
        return <PricingStep />;
      case 3:
        return <ClassificationStep />;
      default:
        return "Unknown step";
    }
  };

  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content-container">
        <div className="main-content-tickets">
          <div className="full-width-profile">
            <BigStepper activeStep={activeStep} setActiveStep={setActiveStep} />
            {getStepContent(activeStep)}
            <hr className="full-width-line" />
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button
                variant="outlined"
                onClick={() => setActiveStep((prev) => Math.max(prev - 1, 0))}
                disabled={activeStep === 0}
              >
                Previous
              </Button>
              <Button
                variant="solid"
                color="primary"
                onClick={() => setActiveStep((prev) => Math.min(prev + 1, 3))}
              >
                {activeStep === 3 ? "Create" : "Next"}
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Create;

import * as React from "react";
import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { alpha } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import "./Hero.css"; // Import the CSS file for transitions
import { Link } from "react-router-dom";

export default function Hero() {
  const [text, setText] = useState("event creators");
  const [inProp, setInProp] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setInProp(false);
      setTimeout(() => {
        setText((prevText) =>
          prevText === "event creators" ? "event goers" : "event creators"
        );
        setInProp(true);
      }, 500); // Match the timeout duration with the transition duration
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundImage:
          theme.palette.mode === "light"
            ? "linear-gradient(180deg, #ABFFE9, #FFF)"
            : `linear-gradient(#00000, ${alpha("#090E10", 0.0)})`,
        backgroundSize: "100% 20%",
        backgroundRepeat: "no-repeat",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: "100%", sm: "70%" } }}>
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignSelf: "center",
              textAlign: "center",
              fontSize: "clamp(6rem, 10vw, 4rem)",
            }}
          >
            The app for
          </Typography>
          <CSSTransition
            in={inProp}
            timeout={500}
            classNames="fade"
            unmountOnExit
          >
            <Typography
              variant="h1"
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignSelf: "center",
                textAlign: "center",
                fontSize: "clamp(6rem, 10vw, 4rem)",
                marginTop: -2,
                marginBottom: 2,
              }}
            >
              {text}
            </Typography>
          </CSSTransition>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
          >
            Empower your brand, create unforgettable events and connect with
            your audience. Discover and get access to the latest happenings in
            your area.
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: "center", width: { sm: "100%", md: "80%" } }}
            fontWeight={700}
          >
            START TODAY
          </Typography>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "center" }} // Center items on mobile
            justifyContent={{ xs: "center" }} // Center items on mobile
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <a
              href="https://apps.apple.com/us/app/popat/id6463745179"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                component="img"
                src="https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/appstore.svg"
                alt="Available on App Store for iOS devices"
                sx={{
                  width: "auto",
                  height: "50px",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.src =
                    "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/appstorehover.svg")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.src =
                    "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/appstore.svg")
                }
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.popatco.PopatMobileApp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                component="img"
                src="https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/googleplay.svg"
                alt="Available on Google Play Store for android devices"
                sx={{
                  width: "auto",
                  height: "50px",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.src =
                    "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/googleplayhover.svg")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.src =
                    "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/googleplay.svg")
                }
              />
            </a>
            <Link to="/explore">
              <Box
                component="img"
                src="https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/wv.svg"
                alt="Available on Web"
                sx={{
                  width: "auto",
                  height: "50px",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.src =
                    "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/web_v.svg")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.src =
                    "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/wv.svg")
                }
              />
            </Link>
          </Stack>
        </Stack>
        <Box
          id="image-container"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: "center",
            height: { xs: 200, sm: 700 },
            width: "100%",
            position: "relative",
            borderRadius: "10px",
            outline: "1px solid",
            outlineColor:
              theme.palette.mode === "light"
                ? alpha("#BCE7DB", 0.5)
                : alpha("#BCE7DB", 0.1),
            boxShadow:
              theme.palette.mode === "light"
                ? `0 0 12px 8px ${alpha("#BCE7DB", 0.2)}`
                : `0 0 24px 12px ${alpha("#BCE7DB", 0.2)}`,
            overflow: "hidden", // Ensure images don't overflow out of the container
          })}
        >
          {/* First Image */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundImage:
                'url("https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/hero1.png")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              animation: `fadeInOut 8s infinite ease-in-out`,
            }}
          />

          {/* Second Image */}
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              backgroundImage:
                'url("https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/hero2.png")',
              backgroundSize: "cover",
              backgroundPosition: "center",
              animation: `fadeInOut 8s infinite ease-in-out`,
              animationDelay: "4s", // Start this animation 4 seconds after the first one
            }}
          />

          <style jsx>{`
            @keyframes fadeInOut {
              0% {
                opacity: 0;
              }
              25% {
                opacity: 1;
              }
              50% {
                opacity: 1;
              }
              75% {
                opacity: 0;
              }
              100% {
                opacity: 0;
              }
            }
          `}</style>
        </Box>
      </Container>
    </Box>
  );
}

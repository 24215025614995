import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { POPAT_APIKEY } from "@env"; // Ensure you have a way to load environment variables in React.js

const initialState = {
  accountuser: null,
  isError: false,
  isSuccess: false,
  FeedListingLoading: false,
  message: null,
  FeedListingdata: null,
  ListingInfo: null,
  CounterInfo: null,
  creatorInfoData: null,
  creatorProfileInfoData: null,
  followCreatorInfo: null,
  loading: null,
  Postidentifierdata: null,
  scannedEventdata: null,
  getTicketAddtoCartdata: null,
  itemsOnCart: [],
  getNotificationData: [],
};

const POPAT_APIKEY = "https://backend.popat.io/api/";
// Utility function to get token from localStorage
const getToken = () => localStorage.getItem("token");

export const FeedListingDataInfo = createAsyncThunk(
  "feedListing/userfeedListing",
  async (_, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .get("feed/")
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const CounterDataInfo = createAsyncThunk(
  "CounterInfo/userListingInfo",
  async (_, { rejectWithValue }) => {
    const tokengot = getToken();
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (tokengot) {
      const infoneeded = `Bearer ${tokengot}`;
      headers.Authorization = infoneeded;
    }

    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: headers,
    });

    return await instance
      .get("counter/")
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data?.results;
        return rejectWithValue(errdata);
      });
  }
);

export const ListingDataInfo = createAsyncThunk(
  "ListingInfo/userListingInfo",
  async (_, { rejectWithValue }) => {
    const tokengot = getToken();
    let headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    if (tokengot) {
      const infoneeded = `Bearer ${tokengot}`;
      headers.Authorization = infoneeded;
    }

    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: headers,
    });

    return await instance
      .get("pop/")
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data?.results;
        return rejectWithValue(errdata);
      });
  }
);

export const CreatorProfileInfo = createAsyncThunk(
  "creatorProfileInfo/userCreatorProfile",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .get(`/users/${data}/`)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const PostIdentifierInfo = createAsyncThunk(
  "postIdentifierInfo/userPostIdentifierInfo",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .get(`/users/${data}/`)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const FollowCreatorInfo = createAsyncThunk(
  "followcreator/userFollowCreator",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .post(`/users/${data}/follow/`)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        alert(`${errdata?.detail}`);
        return rejectWithValue(errdata);
      });
  }
);

export const ItemsOnCart = createAsyncThunk(
  "itemsonCart/userItemsonCart",
  async (_, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .get("estore/order-summary/")
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const GetNotificationApi = createAsyncThunk(
  "getNotificationApi/userGetNotificationApi",
  async (_, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .get("notifications/")
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const ItemsAddtoCart = createAsyncThunk(
  "itemsAddonCart/userItemsAddonCart",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .post("estore/add-to-cart/", data)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const ItemsRemoveCart = createAsyncThunk(
  "ItemsRemoveCart/userItemsRemoveCart",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .post("estore/order-item/update-quantity/", data)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const ItemsDeleteCart = createAsyncThunk(
  "ItemsDeleteCart/userItemsDeleteCart",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .post("estore/order-item/delete/", data)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const eventTicketAddtoCart = createAsyncThunk(
  "eventTicketAddtoCart/eventTicketAddtoCart",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .post("events/tickets/add-to-cart/", data)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const getTicketAddtoCart = createAsyncThunk(
  "getTicketAddtoCart/usergetTicketAddtoCart",
  async (_, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .get("events/tickets/order-summary/")
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const ScannedEventDetails = createAsyncThunk(
  "scannedevent/userScannedEventDetails",
  async (data, { rejectWithValue }) => {
    const tokengot = getToken();
    const infoneeded = `Bearer ${tokengot}`;
    const instance = axios.create({
      baseURL: POPAT_APIKEY,
      timeout: 20000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: infoneeded,
      },
    });
    return await instance
      .get(`events/${data}/`)
      .then((response) => response.data)
      .catch((err) => {
        let errdata = err.response.data;
        return rejectWithValue(errdata);
      });
  }
);

export const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    reset: (state) => {
      state.FeedListingdata = null;
      state.FeedListingLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
    resetScannedEvent: (state) => {
      state.scannedEventdata = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FeedListingDataInfo.pending, (state) => {
        state.FeedListingLoading = true;
      })
      .addCase(FeedListingDataInfo.fulfilled, (state, action) => {
        state.FeedListingLoading = false;
        state.isSuccess = true;
        state.FeedListingdata = action.payload;
      })
      .addCase(FeedListingDataInfo.rejected, (state, action) => {
        state.FeedListingLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(ListingDataInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(ListingDataInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.ListingInfo = action.payload;
      })
      .addCase(ListingDataInfo.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(CounterDataInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(CounterDataInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.CounterInfo = action.payload;
      })
      .addCase(CounterDataInfo.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(CreatorProfileInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(CreatorProfileInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.creatorProfileInfoData = action.payload;
      })
      .addCase(CreatorProfileInfo.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(FollowCreatorInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(FollowCreatorInfo.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.followCreatorInfo = action.payload;
      })
      .addCase(FollowCreatorInfo.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(ItemsOnCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(ItemsOnCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.itemsOnCart = action.payload;
      })
      .addCase(ItemsOnCart.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(GetNotificationApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetNotificationApi.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.getNotificationData = action.payload;
      })
      .addCase(GetNotificationApi.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(ItemsAddtoCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(ItemsAddtoCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.getTicketAddtoCartdata = action.payload;
      })
      .addCase(ItemsAddtoCart.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(ItemsRemoveCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(ItemsRemoveCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.getTicketAddtoCartdata = action.payload;
      })
      .addCase(ItemsRemoveCart.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(ItemsDeleteCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(ItemsDeleteCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.getTicketAddtoCartdata = action.payload;
      })
      .addCase(ItemsDeleteCart.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(eventTicketAddtoCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(eventTicketAddtoCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.getTicketAddtoCartdata = action.payload;
      })
      .addCase(eventTicketAddtoCart.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTicketAddtoCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTicketAddtoCart.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.getTicketAddtoCartdata = action.payload;
      })
      .addCase(getTicketAddtoCart.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(ScannedEventDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(ScannedEventDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.scannedEventdata = action.payload;
      })
      .addCase(ScannedEventDetails.rejected, (state, action) => {
        state.loading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset, resetScannedEvent } = dataSlice.actions;
export default dataSlice.reducer;

import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import TicketList from "./TicketList";
import image from "../assets/default.png";
import { BiPlus, BiMinus } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";

const SingleTicket = () => {
  return (
    <div className="flex items-center justify-between">
      <div
        style={{
          borderRadius: 25,
          backgroundColor: "whitesmoke",
          padding: 15,
          marginLeft: 20,
          marginRight: 20,
          paddingRight: 25,
        }}
        className="flex items-center w-[93%] justify-between bg-white px-3 py-3 rounded-[12px]"
      >
        <div className="flex items-center gap-x-5">
          <img
            src={image}
            alt=""
            className="w-[130px] h-[100%] object-cover rounded-[15px]"
          />
          <div className="w-[250px]">
            <h2 className="truncate-title text-[17px] font-semibold">
              Event Title Goes Here
            </h2>
            <p className="truncate-caption text-sm">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Lorem,
              ipsum dolor sit amet consectetur adipisicing elit. Lorem, ipsum
              dolor sit amet consectetur adipisicing elit. Laboriosam
            </p>
          </div>
        </div>
        <div className="flex items-center gap-x-3">
          <button className="bg-gray-200 p-1 rounded-full button-hover-green">
            <BiMinus className="icon" />
          </button>
          <p className="font-semibold">2</p>
          <button className="bg-gray-200 p-1 rounded-full button-hover-green">
            <BiPlus className="icon" />
          </button>
        </div>
        <div>
          <p className="text-[16px] font-semibold">N75,000,000.00</p>
        </div>
      </div>

      <div>
        <button
          // style={{ backgroundColor: "whitesmoke" }}
          className="bg-#F5F5F5 p-2 rounded-full button-hover"
        >
          <FaTimes className="icon" />
        </button>
      </div>
    </div>
  );
};

const TicketsContainer = () => {
  return (
    <div
      style={{
        padding: "20px",
        // backgroundColor: "whitesmoke",
        // borderRadius: 25,
      }}
    >
      <div className="flex flex-col gap-y-4">
        <h2 className="big-bold-text">Cart</h2>

        <SingleTicket />
        <SingleTicket />
        <SingleTicket />
        <SingleTicket />
        <SingleTicket />
        <SingleTicket />
      </div>
    </div>
  );
};

const CartSummary = () => {
  return (
    <div
      style={{ backgroundColor: "whitesmoke" }}
      className="w-[100%] p-5 rounded-[24px]"
    >
      <h2 className="font-semibold text-xl mb-5">Summary</h2>
      <div className="mb-10 flex items-center space-x-3">
        <input
          type="text"
          t
          className="w-full py-2 px-3 text-[15px] mb-3 rounded-[60px]"
          placeholder="Enter coupon code"
          style={{ border: "1px solid lightgray" }}
        />
        <button className="bg-yellow-400 hover:bg-yellow-500 font-semibold px-3 py-1 rounded-[25px] text-[15px] text-black">
          Add
        </button>
      </div>
      <div className="mt-2 mb-5 text-center">
        <h2 className="font-semibold">Total</h2>
        <p className="text-2xl font-bold">N75,000,000.00</p>
      </div>
      <div className="flex justify-center">
        <button className="bg-[#08A67B] hover:bg-[#06885f] text-white w-[70%] rounded-[25px] font-medium py-2 flex items-center justify-center">
          Checkout <FaChevronRight className="ml-2" />
        </button>
      </div>
    </div>
  );
};

const Cart = () => {
  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content-container">
        <Header />
        <div className="main-content-tickets">
          <div className="part-a">
            <div className="full-width-card">
              <TicketsContainer />
            </div>
          </div>
          <div className="part-b-cart">
            <CartSummary />
          </div>
        </div>
      </div>
    </div>
  );
};

// const Cart = () => {
//   return (
//     <div className="w-full h-full flex">
//       <div className="w-[18%]">
//         <Sidebar />
//       </div>
//       <div className="w-[82%]">
//         <Header />
//         <div className="flex p-5 justify-between items-start">
//           <TicketsContainer />
//           <CartSumamry />
//         </div>
//       </div>
//     </div>
//   );
// };

export default Cart;

import React, { useState } from "react";
import "./Profile.css"; // Assuming this contains your custom styles
// import "./MainContent.css"; // Assuming this contains your custom styles
import Sidebar from "./Sidebar"; // Assuming these are your components
import Header from "./Header";
import TicketList from "./TicketList";
import ProfileCard from "./ProfileCard";
import profileImage from "../assets/default.png"; // Replace with your image path

const Profile = () => {

  const [vendor, setVendor] = useState(false);

  return (
    <div className="app-container">
      <Sidebar />
      <div className="lg:ml-60 w-full bg-slate-200 rounded-lg">
        <div className="m-10 p-10 rounded-xl bg-white shadow-lg flex flex-col gap-2">
          <div className="">
            <ProfileCard/>
          </div>
          <div className="">
            <h2 style={{ color: "white", fontSize: 25, fontWeight: "bold" }}>
              Become a Creator
            </h2>
            <hr className="full-width-line" />
             {vendor ? (
              <h2>Created Events</h2>
            ) : (
              <h2>Encourgae to become a creator</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

// tokenStorage.js
export const getAccessToken = () => {
    // Retrieve the token from localStorage
    return localStorage.getItem('accessToken');
  };
  
  export const setAccessToken = (token) => {
    // Store the token in localStorage
    localStorage.setItem('accessToken', token);
  };
  
  // You may also want a function to remove the token
  export const removeAccessToken = () => {
    localStorage.removeItem('accessToken');
  };

  export const getUsername = () => {
    // Retrieve the token from localStorage
    return localStorage.getItem('username');
  };
  
  export const setUsername = (username) => {
    // Store the token in localStorage
    localStorage.setItem('username', username);
  };
  
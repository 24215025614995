import React, { useEffect, useState } from 'react';
import { getAccessToken } from '../../../Authentication/tokenStorage';
import { Link } from 'react-router-dom';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import './pop.css';

const PopEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEvents = async (token) => {
    console.log('Retrieved token:', token);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://backend.popat.io/api/pop/", requestOptions);
      console.log('Response:', response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Result:', result);

      setEvents(result.data.results);
    } catch (error) {
      console.error('Fetch error:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    console.log('Token retrieved from tokenStorage:', token);

    if (token) {
      fetchEvents(token);
    } else {
      console.log('No token available yet');
      setLoading(false);
    }
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  const timeSince = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const seconds = Math.floor((now - postedDate) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return `${interval} years ago`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return `${interval} months ago`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return `${interval} days ago`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return `${interval} hours ago`;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return `${interval} minutes ago`;
    }
    return `${Math.floor(seconds)} seconds ago`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='w-full'>
      {events.length < 1 ? (
        <div style={{ marginTop: "40%", textAlign: "center" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "15px" }}>
            <p className="oops">Oops! No pops yet.</p>
            <p className="oops">Why don't you create one?</p>
          </div>
          <div style={{ margin: "0 20px" }}>
            {/* Optional: Add a button or link to create a new event */}
          </div>
        </div>
      ) : (
        <ul className='w-full grid grid-cols-3 gap-4'>
          {events.map(event => (
            <li key={event.id} className='relative'>
              <Link to={`/event/${event.id}`}>
                <img src={event.photo} alt={event.title} className='rounded-lg w-full h-full object-cover' />
                <div className='absolute inset-0 bg-black bg-opacity-50 rounded-lg'></div>
                <div className='absolute top-2 right-2 bg-white rounded-lg p-1 flex flex-col items-center text-sm'>
                  <p>{new Date(event.date_of_event).toLocaleDateString('en-GB', {
                    day: '2-digit'
                  })}</p>
                  <p>{new Date(event.date_of_event).toLocaleDateString('en-GB', {
                    month: 'short'
                  }).toUpperCase()}</p>
                  <p className='py-1 border-t border-black'>
                    {new Date(event.date_of_event).toLocaleTimeString('en-US', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true
                    })}</p>
                </div>
                <div className='absolute top-2 left-2 w-10 h-10 bg-white rounded-full flex items-center justify-center'>
                  <img src={event.author.image} alt="" className='w-8 h-8 rounded-full' />
                </div>
                <div className='w-full flex items-center justify-between absolute bottom-3 p-1 px-2 gap-4 text-white text-sm'>
                  <div className='w-2/3 flex flex-col gap-2'>
                    <div className='flex items-center gap-4'>
                      <div className='flex items-center gap-2 bg-opacity p-2 rounded-full'>
                        <FavoriteBorderOutlinedIcon />
                        <p>{event.total_likes}</p>
                      </div>
                      <div className='w-8 h-8 bg-opacity flex items-center justify-center rounded-full'>
                        <FlagOutlinedIcon />
                      </div>
                    </div>
                    <div className=''>
                      <p className='font-semibold text-base'>{truncateText(event.title, 14)}</p>
                      <p>{truncateText(event.caption, 30)}</p>
                    </div>
                    <div className='flex items-center gap-2'>
                      <LocationOnOutlinedIcon />
                      <p>Ikeja</p>
                    </div>
                  </div>
                  <div className='mt-2 w-1/2 flex flex-col gap-2 items-end'>
                    <p className=''>{event.price ? `Price: ${event.price}` : 'Free Event'}</p>
                    <button className={`px-2 py-1 rounded-full text-white text-xs font-semibold uppercase  
                      ${event.price ? 'bg-yellow-500' : 'bg-[#08A67B]'}`}>
                      {event.price ? 'Get Ticket' : 'Get Free Ticket'}
                    </button>
                    <p className=''>{timeSince(event.date_posted)}</p>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PopEvents;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const tiers = [
  {
    title: "Free",
    price: "0",
    description: [
      "Unlimited Free, Private & Paid Events",
      "Unlimited attendee Check-in",
      "Free & Paid Event",
      "Unlimited Access",
      "Unlimited Messaging Attendees",
      "Instant Payout",
      "NGN200 attendee charge + 2.5% transaction fee on paid tickets",
    ],
    buttonText: "Start now",
    buttonVariant: "contained",
  },
  {
    // title: "Premium Features",
    subheader: "Recommended",
    price: "15",
    description: [
      "Custom Event Branding",
      "Event Organizing and Staffing",
      "Verified Badge",
      "Partnership & Promotions",
      "Access Control",
      "0% Transaction Fee",
    ],
    buttonText: "Contact us",
    buttonVariant: "outlined",
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Pricing
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Choose what suits you: Free for everyone, custom solutions by inquiry.
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: tier.title === "Free" ? "1px solid" : undefined,
                borderColor:
                  tier.title === "Free" ? "primary.main" : "undefined",
                background:
                  tier.title === "Free"
                    ? "linear-gradient(#033363, #fffff)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // color: tier.title === "Free" ? "grey.100" : "",
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.title === "Free" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      // sx={{
                      //   background: (theme) =>
                      //     theme.palette.mode === "light" ? "" : "none",
                      //   backgroundColor: "primary.contrastText",
                      //   "& .MuiChip-label": {
                      //     color: "primary.dark",
                      //   },
                      //   "& .MuiChip-icon": {
                      //     color: "primary.dark",
                      //   },
                      // }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    // color: tier.title === "Free" ? "grey.50" : "undefined",
                  }}
                >
                  {/* tier.title === "Premium Features" ? "primary.main" :
                  undefined, */}

                  {tier.title !== "Free" ? (
                    <>
                      <Typography component="h3" variant="h6">
                        Custom
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography component="h3" variant="h2">
                        ₦{tier.price}
                      </Typography>
                    </>
                  )}
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === "Free"
                            ? "primary.main"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      // sx={{
                      //   color: tier.title === "Free" ? "grey.200" : undefined,
                      // }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  href={tier.title === "Free" ? "/create" : "/contact-us"}
                  target="_blank"
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

import React from "react";
import {
  FaHome,
  FaCompass,
  FaPlusCircle,
  FaTicketAlt,
  FaUser,
  FaCog,
  FaInfoCircle,
  FaUserCog,
} from "react-icons/fa";
import { GoHomeFill } from "react-icons/go";
import logo from "./Logo.png"; // Importing the image
import { Link } from "react-router-dom";
import { IoCalendar } from "react-icons/io5";
import { AiFillMessage } from "react-icons/ai";
import { HiMiniUsers } from "react-icons/hi2";
import { HiOutlineLogout } from "react-icons/hi";

const SideNavItem = ({ children, isActive, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="flex items-center gap-[15px] px-5 rounded-[24px] py-3 cursor-pointer hover:bg-gray-200 transition-colors duration-200"
      style={{ color: isActive ? "#08A67B" : "rgba(0,0,0,0.3)" }}
    >
      {children}
    </div>
  );
};

const Sidebar = () => {
  const [activeLink, setActiveLink] = React.useState("home");
  console.log(activeLink);
  return (
    <div className="w-[15%] px-[30px] py-[20px] flex flex-col h-[100%] fixed z-20 bg-white">
      <div className="w-[150px] h-[80px] mb-[30px]">
        <img src={logo} alt="" className="w-[100%] h-[100%] object-contain" />
      </div>

      <div className="flex flex-col justify-between h-[100%]">
        <div className="flex flex-col gap-y-[5px]">
          <Link to="/home">
            <SideNavItem
              isActive={activeLink === "home"}
              onClick={() => setActiveLink("home")}
            >
              <GoHomeFill size={23} />
              <p className="text-[15px] font-medium">Home</p>
            </SideNavItem>
          </Link>
          <Link to="/discover">
            <SideNavItem
              isActive={activeLink === "discover"}
              onClick={() => setActiveLink("discover")}
            >
              <FaCompass size={23} />
              <p className="text-[15px] font-medium">Discover</p>
            </SideNavItem>
          </Link>
          <Link to="/create">
            <SideNavItem
              isActive={activeLink === "create"}
              onClick={() => setActiveLink("create")}
            >
              <FaPlusCircle size={23} />
              <p className="text-[15px] font-medium">Create</p>
            </SideNavItem>
          </Link>
          <Link to="/tickets">
            <SideNavItem
              isActive={activeLink === "tickets"}
              onClick={() => setActiveLink("tickets")}
            >
              <FaTicketAlt size={23} />
              <p className="text-[15px] font-medium">Tickets</p>
            </SideNavItem>
          </Link>
          <Link to="/profile">
            <SideNavItem
              isActive={activeLink === "profile"}
              onClick={() => setActiveLink("profile")}
            >
              <FaUser size={23} />
              <p className="text-[15px] font-medium">Profile</p>
            </SideNavItem>
          </Link>
        </div>

        <div className="flex flex-col gap-y-[10px]">
          <Link to="/settings">
            <SideNavItem
              isActive={activeLink === "settings"}
              onClick={() => setActiveLink("settings")}
            >
              <FaUserCog size={23} />
              <p className="text-[15px] font-medium">Settings</p>
            </SideNavItem>
          </Link>
          <Link to="/signin">
            <SideNavItem>
              <HiOutlineLogout size={23} />
              <p className="text-[15px] font-medium">Sign in</p>
            </SideNavItem>
          </Link>
        </div>

        <div>
          <button className="w-[80%] mx-auto py-3 text-[15px] font-medium bg-[#08A67B] text-white rounded-[24px] shadow-md hover:shadow-green-400 transition-shadow duration-200">
            More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

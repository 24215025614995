import React, { useEffect, useState } from "react";
import { getAccessToken } from "../../../../pages/Authentication/tokenStorage"; // Adjust the import path as needed

const ExpiredTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getAccessToken();
    console.log("Retrieved token:", token); // Log the retrieved token

    if (!token) {
      console.error("No access token found");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://backend.popat.io/api/estore/t-summary/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        const closedOrders = result.closed_orders || [];
        setTickets(closedOrders);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full h-full overflow-auto">
      {tickets.length > 0 ? (
        <div className="w-full flex flex-col gap-2">
          {tickets.map((ticket) => {
            const date = new Date(ticket.original_event_date);
            const formattedDate = date.toISOString().split("T")[0];
            const formattedTime = date.toTimeString().split(" ")[0];

            return (
                <div key={ticket.id} className="w-full flex bg-gray-200 items-center rounded-xl p-3 gap-4">
                    <img src={ticket.image} alt={ticket.name} className="w-28 h-28 rounded-lg" />
                    <div className="flex flex-col gap-1">
                    <p className="text-sm font-semibold">{ticket.name}</p>

                    <div className="flex text-sm gap-4 items-center">
                        <div className="flex flex-col gap-1 items-start">
                        <p>Date:</p>
                        <p>{formattedDate}</p>
                        </div>
                        <div className="flex flex-col gap-1 items-start">
                        <p>Time:</p>
                        <p>{formattedTime}</p>
                        </div>
                    </div>
                    <div className="flex gap-1 items-center text-[#08A67B] font-medium">
                        <p>Ticket admits:</p>
                        <p>{ticket.quantity}</p>
                    </div>
                    </div>
                </div>
            );
          })}
        </div>
      ) : (
        <div>
          <p className="text-lg font-medium">No closed orders available</p>
        </div>
      )}
    </div>
  );
};

export default ExpiredTickets;

import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import GoogleMapReact from "google-map-react";
import DiscoverEvents from "./highlight/DiscoverEvents";
import { FaMapMarkerAlt } from "react-icons/fa";

const Marker = ({ text }) => (
  <div style={{ color: "red", fontSize: "24px" }}>
    <FaMapMarkerAlt />
  </div>
);

function MainDiscover() {
  const defaultProps = {
    center: {
      lat: 9.064324,
      lng: 7.457752,
    },
    zoom: 30,
  };

  const [mapCenter, setMapCenter] = useState(defaultProps.center);
  const [markerPosition, setMarkerPosition] = useState(null);

  const parseLocation = (locationString) => {
    const match = locationString.match(/POINT \(([^ ]+) ([^)]+)\)/);
    if (match) {
      return {
        lng: parseFloat(match[1]),
        lat: parseFloat(match[2]),
      };
    }
    return null;
  };

  const handleEventHover = (locationString) => {
    console.log("Location passed into handleEventHover:", locationString); // Log the location string
    const location = parseLocation(locationString);
    if (location) {
      setMapCenter(location);
      setMarkerPosition(location);
    }
  };

  return (
    <div className="app-container flex flex-row h-screen">
      <Sidebar />
      <div className="main-content-container flex flex-col flex-grow">
        {/* <Header /> */}
        <div className="main-content-discover flex flex-grow">
          <div
            style={{ borderRadius: "25px", margin: 20 }}
            className="flex flex-col gap-4 bg-white w-1/3 p-8 overflow-auto"
          >
            <h2 className="big-bold-text">Discover</h2>
            <DiscoverEvents onEventHover={handleEventHover} />
          </div>

          <div
            style={{ borderRadius: "25px", margin: 20 }}
            className="w-2/3 h-full"
          >
            <GoogleMapReact
              key={JSON.stringify(mapCenter)} // Add key to force re-render
              bootstrapURLKeys={{
                key: "AIzaSyCKVAFUpbEimE4E_Rv-wdtW1ibhUiUaY7U",
              }}
              center={mapCenter}
              zoom={10} // Updated zoom level
            >
              {markerPosition && (
                <Marker
                  lat={markerPosition.lat}
                  lng={markerPosition.lng}
                  text="Event Location"
                />
              )}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainDiscover;

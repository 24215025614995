import React from "react";
import "./MainContent.css";
import "./Ticket.css";
import Sidebar from "./Sidebar";
import Header from "./Header";
import TicketList from "./TicketList";
import { Player } from "@lottiefiles/react-lottie-player";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import TicketListDetails from "./TicketListDetails";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import OpenTickets from "./tickets/OpenTickets";
import ExpiredTickets from "./tickets/ExpiredTickets";
import ClosedTickets from "./tickets/ClosedTickets";

const Tickets = () => {

    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content-container">
        {/* <Header /> */}
        <div className="main-content-tickets">
          <div className="part-b">
            {/* <div className="set1">
              <DotLottieReact
                src="https://lottie.host/5151ea25-50f2-4d36-8a07-758014a8fd42/FHuwqZ9HVW.lottie"
                className="player"
                loop
                autoplay
              />
            </div> */}

            <h2 className="big-bold-text">Tickets</h2>
            <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        // aria-label="secondary tabs example"
      >
            <Tab label="Open Tickets" value="1" />
            <Tab label="Reservations"  value="2" />
            <Tab label="Expired Tickets" value="3" />
          </Tabs>
        </Box>
        
        <TabPanel value="1">
          <OpenTickets />
        </TabPanel>

        <TabPanel value="2">
          <ClosedTickets />
        </TabPanel>

        <TabPanel value="3">
          <ExpiredTickets />
        </TabPanel>
      </TabContext>
    </Box>
            {/* <TicketList /> */}
          </div>
          <div className="part-a">
            <div className="full-width-ticket">
              <TicketListDetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tickets;

import React from 'react';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import MainContent from './components/MainContent';
import './components/Dashboard.css';

function Dashboard() {
  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content-container">
        <Header />
        <MainContent /> 
      </div>
    </div>
  );
}

export default Dashboard;

// App.js
import "./AppSection.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Authentication/Login";
import SignUp from "./pages/Authentication/SignUp";
import GetOtp from "./pages/Authentication/GetOtp";
import ForgetPassword from "./pages/Authentication/ForgetPassword";
import CheckEmail from "./pages/Authentication/CheckEmail";
import VerificationCode from "./pages/Authentication/VerificationCode";
import ChangePassword from "./pages/Authentication/ChangePassword";
import Main from "./pages/Main/Dashboard";
import CreatorProfile from "./pages/Main/CreatorProfile";
import CreatorProfilePersonal from "./pages/Main/CreatorProfilePersonal";
import EditDetail from "./pages/Main/EditDetail";
import EditEvent from "./pages/Main/EditEvent";
import Creator from "./pages/Main/Creator";
import CreatorPop from "./pages/Main/CreatorPop";
import FAQ from "./pages/Footer/FAQ";
import PrivacyPolicy from "./pages/Footer/PrivacyPolicy";
import ContactUs from "./pages/Authentication/ContactUs";
import HelpDesk from "./pages/Footer/HelpDesk";
import AboutUsTeam from "./pages/Footer/AboutUsTeam";
import AboutUs from "./pages/Footer/AboutUs";
import Discover from "./pages/Main/Discover";
import DiscoverOne from "./pages/Main/DiscoverOne";
import EditCreatorProfile from "./pages/Main/EditCreatorProfile";
import EditCreatorPersonalProfile from "./pages/Main/EditCreatorPersonalProfile";
import Ticket from "./pages/Main/Ticket";
import EventCategories from "./pages/Main/EventCategories";
import BecomeCreator from "./pages/Main/BecomeCreator";

import Build from "./pages/Build";
import GetStarted from "./pages/BuildPages/GetStarted";
import Terms from "./pages/BuildPages/Terms";
import Contact from "./pages/BuildPages/Contact";
import About from "./pages/BuildPages/About";
import PageNotFound from "./pages/BuildPages/PageNotFound";
import AppFinder from "./pages/BuildPages/AppFinder";
import AppF from "./pages/BuildPages/AppF";
import CreateDeep from "./pages/BuildPages/CreateDeep";
import CartDeep from "./pages/BuildPages/CartDeep";
import DiscoverDeep from "./pages/BuildPages/DiscoverDeep";
import NotificationsDeep from "./pages/BuildPages/NotificationsDeep";
import TicketsDeep from "./pages/BuildPages/TicketsDeep";
import ExperienceDeep from "./pages/BuildPages/ExperienceDeep";
import SocialAuth from "./pages/BuildPages/SocialAuth";
// import EventDetails from "./pages/BuildPages/major/EventDetails";
import Dashboard from "./pages/Modern/Dashboard";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
// import AppleAppSiteAssociation from "./pages/BuildPages/AppleAppSiteAssociation";
// import AssetLinks from "./pages/BuildPages/AssetLinks";
import { Provider, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import MainDiscover from "./pages/Modern/components/MainDiscover";
import Create from "./pages/Modern/components/Create";
import Tickets from "./pages/Modern/components/Tickets";
import Profile from "./pages/Modern/components/Profile";
import Notifications from "./pages/Modern/components/Notifications";
import Cart from "./pages/Modern/components/Cart";
import PopEvents from "./pages/Modern/components/highlight/PopEvents";
import Retrieved from "./pages/Authentication/Retrieved";
import EventDetails from "./pages/Modern/components/highlight/EventDetails";
import LandingPage from "./general/LandingPage";
import Shop from "./general/Shop";
import PricingPage from "./general/PricingPage";

function App() {
  return (
    // ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            {/* Authentication routes */}
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/signin" element={<LoginPage />} />
            <Route exact path="/forget-password" element={<ForgetPassword />} />
            <Route exact path="/change-password" element={<ChangePassword />} />
            <Route exact path="/check-email" element={<CheckEmail />} />
            <Route
              exact
              path="/verification-code/:email"
              element={<VerificationCode />}
            />
            <Route path="*" element={<PageNotFound />} />
            {/* Authentication routes */}

            {/* AppPages */}
            <Route exact path="/home" element={<Dashboard />} />
            <Route exact path="/event/:id" element={<EventDetails />} />
            <Route exact path="/discover" element={<MainDiscover />} />
            <Route exact path="/create" element={<Create />} />
            <Route exact path="/tickets" element={<Tickets />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/notifications" element={<Notifications />} />
            <Route exact path="/cart" element={<Cart />} />
            {/* AppPages */}

            {/* GeneralPages */}
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/shop" element={<Shop />} />
            <Route exact path="/about-us" element={<Shop />} />
            <Route exact path="/pricing" element={<PricingPage />} />
            <Route exact path="/faqs" element={<Shop />} />

            <Route exact path="/terms" element={<Terms />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

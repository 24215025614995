import React from 'react';
import Logo from '../../Images/logo.png'


const CheckEmail = () => {

    return (
        <div className='email-background w-full h-screen flex flex-col gap-6 items-center justify-center p-6'>
                <img src={Logo} alt="" style={{ paddingTop: "1px", paddingBottom: "30px", width: "230px", height: "auto" }} />

          <form style={{borderRadius: 25}} className='md:w-3/4 lg:w-1/2 flex flex-col gap-4 bg-white rounded items-center p-12 shadow-xl'>
            <div className='flex flex-col gap-2 items-center w-full'>
                <h1 className='text-xl text-center md:text-2xl font-semibold'>
                Great!
                </h1>

                <p className='text-center'>
                Please check your mail for One-time password
                </p>
            </div>
            
            <button type="submit" className='w-full  p-3 rounded-full text-white font-semibold bg-[#08A67B]'>
                Ok
            </button>
        </form>
        </div>
    )
}

export default CheckEmail

// getEmail.js

let savedEmail = '';

export const setEmail = (email) => {
    savedEmail = email;
    console.log('Email saved:', savedEmail);
};

export const getEmail = () => {
    return savedEmail;
};

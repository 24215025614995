import React, { useState } from 'react';
import { Box, Card, Typography, Avatar, Button, Divider } from '@mui/material';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
// import ImageSlider from './ImageSlider'; // Assume you have an ImageSlider component
import SimpleImageSlider from "react-simple-image-slider";
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const Highlight = () => {
  const [tabValue, setTabValue] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setCurrentSlide(0); // Reset to the first slide when the tab changes
  };

  const getEventContent = (tab) => {
    switch (tab) {
      case 0:
        return { title: 'Hottest Event', events: [{ event_title: 'Event 1', image: 'img1.jpg' }, { event_title: 'Event 2', image: 'img2.jpg' }] };
      case 1:
        return { title: 'Social Events', events: [{ event_title: 'Event 3', image: 'img3.jpg' }, { event_title: 'Event 4', image: 'img4.jpg' }] };
      case 2:
        return { title: 'Happening This Week', events: [{ event_title: 'Event 5', image: 'img5.jpg' }, { event_title: 'Event 6', image: 'img6.jpg' }] };
      case 3:
        return { title: 'Corporate Events', events: [{ event_title: 'Event 7', image: 'img7.jpg' }, { event_title: 'Event 8', image: 'img8.jpg' }] };
      default:
        return { title: '', events: [] };
    }
  };

  const { title, events } = getEventContent(tabValue);

  const images = events.map(event => event.image);

  return (
    <Card sx={{ borderRadius: 5, 
        // boxShadow: 3, 
    width: '100%', p: 3 }}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="tabs"
          sx={{ bgcolor: 'transparent' }}
        >
          <TabList
            disableUnderline
            sx={{
              p: 0.5,
              gap: 0.5,
              borderRadius: 'xl',
              bgcolor: 'background.level1',
              [`& .${tabClasses.root}[aria-selected="true"]`]: {
                boxShadow: 'sm',
                bgcolor: 'background.surface',
              },
            }}
          >
            <Tab disableIndicator>Hottest Event</Tab>
            <Tab disableIndicator>Social Events</Tab>
            <Tab disableIndicator>Happening This Week</Tab>
            <Tab disableIndicator>Corporate Events</Tab>
          </TabList>
        </Tabs>
        <Box display="flex" width="100%" mt={3}>
          <Box flex={1} style={{backgroundColor: "white" }}>
            <SimpleImageSlider
              width={400}
              height={400}
              images={images}
              showBullets={true}
              showNavs={true}
              style={{ borderRadius: 30 }}
              onStartSlide={(index) => setCurrentSlide(index - 1)} // Slider index starts from 1
            />
          </Box>
          {/* <Box style={{backgroundColor: "white", marginTop: 100 }}flex={1} display="flex" alignItems="flex-start" justifyContent="flex-start">
            <Typography variant="h5">{events[currentSlide]?.event_title}</Typography>
          </Box> */}
          <Box flex={1} display="flex" flexDirection="column" justifyContent="center" p={2} mr={10}>
  <Box display="flex" alignItems="center" mb={1}>
    <EventIcon fontSize="small" sx={{ mr: 1 }} />
    <Typography variant="body2" sx={{ mr: 2 }}>
      Date
    </Typography>
    <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
    <Typography variant="body2">
      Time
    </Typography>
  </Box>
  <Typography variant="h5" fontWeight="bold" mb={1}>
    {events[currentSlide]?.event_title}
  </Typography>
  <Typography variant="body2" sx={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, overflow: 'hidden', textOverflow: 'ellipsis', mb: 2, mr: 2 }}>
    caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption caption
  </Typography>
  <Divider sx={{ mb: 2 }} />
  <Box display="flex" alignItems="center" justifyContent="space-between">
    <Box display="flex" alignItems="center">
      <Avatar sx={{ mr: 1 }} />
      <Box>
        <Typography variant="body2">Creator</Typography>
        <Typography variant="body2" fontWeight="bold">Creator Name</Typography>
      </Box>
    </Box>
    <Button variant="outlined" sx={{ borderRadius: 25 }}>
      View Profile
    </Button>
  </Box>
</Box>

        </Box>
      </Box>
    </Card>
  );
};

export default Highlight;

import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { IoFilterSharp, IoCart } from "react-icons/io5";
import { IoMdNotifications } from "react-icons/io";
import { Link } from "react-router-dom";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Checkbox from "@mui/joy/Checkbox";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import Stack from "@mui/joy/Stack";
import RadioGroup from "@mui/joy/RadioGroup";
import Radio from "@mui/joy/Radio";
import Sheet from "@mui/joy/Sheet";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import TuneIcon from "@mui/icons-material/TuneRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import MeetingRoomRoundedIcon from "@mui/icons-material/MeetingRoomRounded";
import HotelRoundedIcon from "@mui/icons-material/HotelRounded";
import Done from "@mui/icons-material/Done";
import Notifications from "./Notifications";

const Header = ({ setOpenNotificationModal }) => {
  const [isCategoriesActive, setIsCategoriesActive] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [type, setType] = React.useState("Guesthouse");
  const [amenities, setAmenities] = React.useState([0, 6]);

  return (
    <React.Fragment>
      <div className="w-full sticky top-0 z-20 bg-white">
        <div className="w-[80%] mx-auto flex items-center justify-between py-5 px-5">
          {/* <Link to="/categories"> */}
          <div
            className={`w-[15%] py-3 px-4 justify-center rounded-[24px] flex items-center gap-[10px] cursor-pointer ${
              isCategoriesActive
                ? "bg-[#08A67B] text-white shadow-md hover:text-white"
                : "bg-gray-100 hover:bg-[#08A67B] hover:text-white hover:shadow-md"
            }`}
            onClick={() => setIsCategoriesActive(!isCategoriesActive)}
          >
            <IoFilterSharp
              color={isCategoriesActive ? "white" : "gray"}
              size={25}
              className={
                isCategoriesActive
                  ? "text-white"
                  : "text-gray-500 hover:text-white "
              }
            />
            <p
              className={`text-[15px] ${
                isCategoriesActive
                  ? "text-white"
                  : "text-gray-500 hover:text-white "
              }`}
            >
              Categories
            </p>
          </div>
          {/* </Link> */}
          <div
            // style={{ backgroundColor: "whitesmoke" }}
            className="w-[70%] bg-gray-100 px-4 py-3 rounded-[60px] flex items-center gap-[10px]"
          >
            <BiSearch color="gray" size={25} />
            <input
              type="text"
              placeholder="Search..."
              style={{
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
                width: "100%",
                color: "gray",
              }}
            />
          </div>
          <div className="w-[10%] p-3 flex items-center justify-between">
            <div
              className="relative cursor-pointer"
              onClick={() => setOpen(true)}
            >
              <IoMdNotifications
                size={30}
                className="text-black hover:text-[#08A67B]"
              />
              <div className="absolute top-[-10px] right-[-3px] bg-[crimson] min-w-[20px] min-h-[20px] flex items-center justify-center text-white rounded-full text-[12px]">
                <p>6</p>
              </div>
            </div>
            <Link to="/cart">
              <div className="relative cursor-pointer">
                <IoCart size={30} className="text-black hover:text-[#08A67B]" />
                <div className="absolute top-[-10px] right-[-3px] bg-[red] min-w-[20px] min-h-[20px] flex items-center justify-center text-white rounded-full text-[12px]">
                  <p>8</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      {/* // drawer */}
      <Drawer
        size="md"
        // size="lg"
        variant="plain"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
          s
        >
          <DialogTitle>Notifications</DialogTitle>
          <ModalClose />
          <Divider sx={{ mt: "auto" }} />
          <DialogContent sx={{ gap: 2 }}>
            <Notifications />
          </DialogContent>

          <Divider sx={{ mt: "auto" }} />
          <Stack
            direction="row"
            justifyContent="space-between"
            useFlexGap
            spacing={1}
          >
            <Button
              color="neutral"
              variant="soft"
              onClick={() => setOpen(false)}
            >
              Make all as read
            </Button>
          </Stack>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
};

export default Header;

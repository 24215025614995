import React, { useEffect, useState } from 'react';
import { getAccessToken } from '../../../Authentication/tokenStorage';
import { Link } from 'react-router-dom';
import './pop.css';

const DiscoverEvents = ({ onEventHover }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEvents = async (token) => {
    console.log('Retrieved token:', token);

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch("https://backend.popat.io/api/pop/", requestOptions);
      console.log('Response:', response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Result:', result);

      setEvents(result.data.results);
    } catch (error) {
      console.error('Fetch error:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    console.log('Token retrieved from tokenStorage:', token);

    if (token) {
      fetchEvents(token);
    } else {
      console.log('No token available yet');
      setLoading(false);
    }
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  const timeSince = (date) => {
    const now = new Date();
    const postedDate = new Date(date);
    const seconds = Math.floor((now - postedDate) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return `${interval} years ago`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return `${interval} months ago`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return `${interval} days ago`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return `${interval} hours ago`;
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return `${interval} minutes ago`;
    }
    return `${Math.floor(seconds)} seconds ago`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className='w-full'>
      {events.length < 1 ? (
        <div style={{ marginTop: "40%", textAlign: "center" }}>
          <div style={{ paddingTop: "10px", paddingBottom: "15px" }}>
            <p className="oops">Oops! No pops yet.</p>
            <p className="oops">Why don't you create one?</p>
          </div>
          <div style={{ margin: "0 20px" }}>
            {/* Optional: Add a button or link to create a new event */}
          </div>
        </div>
      ) : (
        <ul className='w-full grid  gap-4'>
          {events.map(event => (
            <li 
              key={event.id} 
              className='relative'
              onMouseEnter={() => {
                console.log('Event location:', event.location);
                onEventHover(event.location);
              }}
            >
              <Link to={`/event/${event.id}`} className='w-full'>
                <div className='w-full flex bg-gray-200 p-3 gap-4 rounded-xl items-center'>
                    <img src={event.photo} alt={event.title} className='rounded-lg w-32 h-32' />

                    <div className='text-base flex flex-col gap-1'>
                        <p className='font-semibold '>{truncateText(event.title, 25)}</p> 
                        <p>{truncateText(event.caption, 60)}</p>

                        <div className='flex gap-3 items-center'>
                          <div className='flex items-center gap-1'>
                            <p>{new Date(event.date_of_event).toLocaleDateString('en-GB', {
                                  day: '2-digit'
                              })}</p>
                              <p>{new Date(event.date_of_event).toLocaleDateString('en-GB', {
                                  month: 'short'
                              }).toUpperCase()}</p>                            
                          </div>
                            <p>
                            {new Date(event.date_of_event).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true
                            })}</p>
                        </div>
                    </div>

                </div>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DiscoverEvents;

import React, { useState } from 'react';
import './TicketListDetails.css'; // Define TicketListDetails.css for styling
import tempImage from '../assets/default.png';
import SimpleImageSlider from "react-simple-image-slider";
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';


const TicketListDetails = () => {

   const images = [
    { url: 'image-url-1' },
    { url: 'image-url-2' },
    { url: 'image-url-3' },
  ];

  return (
    <div className="ticket-details-container">
      {/* <div className="section">
        1/3
      </div> */}
        <SimpleImageSlider
              width={380}
              height={380}
              images={images}
              showBullets={true}
              showNavs={true}
            //   navStyle={col}
              style={{ borderRadius: 30, 
              alignSelf: "center",
              marginBottom: 30,
            }}
              
            //   onStartSlide={(index) => setCurrentSlide(index - 1)} // Slider index starts from 1
            />


      <hr className="dotted-line" />
      <div className="details">
        <div className="titled">Title</div>
        <div className="date-timed">
          <span className="date-icon">    <EventIcon fontSize="small"  />
</span>Date
          <span className="time-icon">    <AccessTimeIcon fontSize="small"  />
</span>Time
        </div>
        <div className="venue">
            <span> <LocationOnIcon fontSize="small"  /></span>Venue</div>
      </div>
    </div>
  );
};

export default TicketListDetails;

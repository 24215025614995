import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Images/logo.png';
import { setEmail } from '../../utilis/getEmail'; // Importing the setEmail function

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email, setEmailState] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            console.log('Sending data:', { email });

            const response = await fetch('https://backend.popat.io/api/password-reset/otp/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }), // Sending email as JSON data
            });

            if (!response.ok) {
                throw new Error('Failed to send OTP');
            }

            console.log('OTP sent successfully');

            const data = await response.json();
            console.log('Response:', data);

            // Update message with the response from the server
            setMessage(data.message);

            // Save the email using the setEmail function
            setEmail(email);

            // Reset fields after successful submission
            setEmailState('');

            // Redirect to /verification-code after 2 seconds
            setTimeout(() => {
                navigate(`/verification-code/${email}`);
            }, 2000);
        } catch (error) {
            console.error('Error sending OTP:', error.message);
            setMessage('Error sending OTP. Please try again.');
        }
    };

    return (
        <div className='email-background w-full h-screen flex flex-col gap-6 items-center justify-center p-6'>
            <img src={Logo} alt="" style={{ paddingTop: "1px", paddingBottom: "30px", width: "230px", height: "auto" }} />

            <form onSubmit={handleSubmit} style={{borderRadius: 25}} className='md:w-3/4 lg:w-1/2 flex flex-col gap-4 bg-white rounded items-center p-12 shadow-xl'>
                <div className='flex flex-col gap-2 items-center w-full'>
                    <h1 className='text-xl text-center md:text-2xl font-semibold'>
                        Forget your password?
                    </h1>

                    <p className='text-center'>
                        {message ? message : "Enter your email address, and we’ll send a One time password (OTP)"}
                    </p>
                </div>

                <div className='w-full flex flex-col gap-1'>
                    <label htmlFor="email" className='text-[#6B7588]'>
                        Email address
                    </label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmailState(e.target.value)}
                        className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                    />
                </div>

                <button type="submit" className='w-full p-3 rounded-full text-white font-semibold bg-[#08A67B]'>
                    Continue
                </button>
            </form>
        </div>
    );
};

export default ForgotPassword;

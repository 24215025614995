import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Images/logo.png';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import { setAccessToken, setUsername as storeUsername } from './tokenStorage'; // Importing the setAccessToken function and renaming setUsername
import { useNavigate } from 'react-router-dom'; // Importing the useNavigate hook
import { Helmet } from 'react-helmet';
import rightside from "../../Images/rightside.png";
import leftside from "../../Images/leftside.png";

const Login = () => {
    const [username, setUsername] = useState(''); // Local state for username
    const [password, setPassword] = useState(''); 
    const [message, setMessage] = useState('');
    const navigate = useNavigate(); // Initializing the useNavigate hook

    const handleSubmit = async (e) => {
        e.preventDefault();

        const apiUrl = 'https://backend.popat.io/api/token/';
        const requestData = {
            username: username.toLowerCase(), // Ensure username is lowercase before sending
            password
        };

        console.log('Data sent to server:', requestData); // Log the data sent to the server

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });

            const data = await response.json();
            console.log('Response from server:', data); // Log the response data

            if (!response.ok) {
                throw new Error(data.detail || 'Login failed');
            }

            // Store the access token
            setAccessToken(data.access);
            console.log('Access Token set in Login:', data.access); // Log the access token

            // Log the entire response to see what fields are available
            console.log('Full Response Data:', data);

            // Store the username if available
            if (data.user) {
                storeUsername(data.user);
                console.log('Your Username:', data.user); // Log the username
            }

            // Reset fields after successful login
            setUsername('');
            setPassword('');
            setMessage(data.message || 'Login successful');

            // Redirect to /home after 3 seconds
            setTimeout(() => {
                navigate('/home');
            }, 3000);
        } catch (error) {
            console.error('Error:', error.message);
            setMessage('Login failed. Please try again.');
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Popat - Expressing and Experiencing Life.</title>
                <meta name="description" content="Discover the leading event platform in Lagos for effortless planning, promotion, and execution." />
                <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
                <meta name="robots" content="index" />
            </Helmet>

            <div className="w-full h-screen flex flex-col items-center justify-center relative">
                <img src={leftside} alt="" className="image left" />
                <img src={rightside} alt="" className="image right" />
                <img src={Logo} alt="" style={{ paddingTop: "100px", paddingBottom: "50px", width: "230px", height: "auto" }} />

                <form onSubmit={handleSubmit} style={{ marginBottom: "150px", borderRadius: 25 }} className='md:w-3/4 lg:w-1/2 flex flex-col gap-4 bg-white  items-center p-12 shadow-xl'>
                    <h1 className='text-2xl font-semibold'>
                        Welcome back!
                    </h1>

                    <div className='w-full flex flex-col gap-1'>
                        <label htmlFor="username" className='text-[#6B7588]'>Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value.toLowerCase())} // Convert to lowercase on change
                            className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                        />
                    </div>
                    <div className='w-full flex flex-col gap-1'>
                        <label htmlFor="password" className='text-[#6B7588]'>Enter Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                        />
                    </div>

                    <div className='w-full flex gap-4 items-center justify-between'>
                        <Link to='/forget-password' className='text-[#08A67B] font-semibold'>Forget password?</Link>
                        <div className='flex gap-1'>
                            <span>New user?</span>
                            <Link to='/signup' className='text-[#08A67B] font-semibold'>Sign up</Link>
                        </div>
                    </div>

                    <button type="submit" className='w-full p-3 rounded-full text-white font-semibold bg-[#08A67B]'>
                        Login
                    </button>

                    <p className='font-semibold text-[#08A67B]'>{message}</p>

                    <p>Or continue with</p>

                    <div className='w-full flex items-center justify-between gap-4'>
                        <button className='w-1/2 flex items-center justify-center rounded-full py-3 px-4 bg-[#408EE8] text-white'>
                            <FacebookIcon /> <h3 style={{ paddingLeft: 10 }}>Facebook</h3>
                        </button>
                        <button className='w-1/2 flex items-center justify-center rounded-full py-3 px-4 bg-[#CB2027] text-white'>
                            <GoogleIcon /> <h3 style={{ paddingLeft: 10 }}>Google</h3>
                        </button>
                        <button className='w-1/2 flex items-center justify-center rounded-full py-3 px-4 bg-[#000000] text-white'>
                            <AppleIcon /> <h3 style={{ paddingLeft: 10 }}>Apple</h3>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Login;

import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import { Link as Linker } from "react-router-dom";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//
// import Logo from "../assets/logoSVG.svg";
import Logo from "../assets/logo.png";
import AppStore from "../assets/ap.png";
import AppStoreHover from "../assets/apOver.png";
import PlayStore from "../assets/ps.png";
import PlayStoreHover from "../assets/psOver.png";

const logoStyle = {
  width: "140px",
  height: "auto",
};

const storeStyle = {
  width: "100px", // Example style
  margin: "0 10px", // Adds space between images
};
const storeStyleAP = {
  width: "100px", // Example style
  // margin: "0 10px", // Adds space between images
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"© Popat Company Limited "}
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function FooterWhite() {
  const [isAppStoreHovered, setIsAppStoreHovered] = useState(false);
  const [isPlayStoreHovered, setIsPlayStoreHovered] = useState(false);

  return (
    <Container
      style={{ background: "#f2f6fc" }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <Box sx={{ ml: "1px" }}>
              <img src={Logo} style={logoStyle} alt="Popat logo" />
            </Box>

            <Typography variant="body2" color="text.secondary" mb={2}>
              Expressing and Experiencing Life.
            </Typography>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Get the app
            </Typography>
            <Box sx={{ ml: "0px", display: "flex", alignItems: "center" }}>
              <img
                src={isAppStoreHovered ? AppStoreHover : AppStore}
                style={storeStyleAP}
                alt="App Store Download button"
                onMouseEnter={() => setIsAppStoreHovered(true)}
                onMouseLeave={() => setIsAppStoreHovered(false)}
              />
              <img
                src={isPlayStoreHovered ? PlayStoreHover : PlayStore}
                style={storeStyle}
                alt="Play Store Download button"
                onMouseEnter={() => setIsPlayStoreHovered(true)}
                onMouseLeave={() => setIsPlayStoreHovered(false)}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={700}>
            Company
          </Typography>
          <Linker to="/about-us">
            <Link color="text.secondary">About Us</Link>
          </Linker>
          <Linker to="/how-popat-works">
            <Link color="text.secondary">How Popat Works</Link>
          </Linker>
          <Linker to="/support">
            <Link color="text.secondary">Support</Link>
          </Linker>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={700}>
            Product
          </Typography>
          <Linker to="/features">
            <Link color="text.secondary">Features</Link>
          </Linker>
          <Linker to="/pricing">
            <Link color="text.secondary">Pricing</Link>
          </Linker>
          <Linker to="/faqs">
            <Link color="text.secondary">FAQs</Link>
          </Linker>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={700}>
            Legal
          </Typography>
          <Linker to="/terms">
            <Link color="text.secondary">Terms</Link>
          </Linker>
          {/* <Linker to="/terms#creators-agreement">
            <Link color="text.secondary">Creator's Agreement</Link>
          </Linker> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Linker to="/terms#privacy-policy">
            <Link color="text.secondary">Privacy Policy</Link>
          </Linker>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Linker to="/terms">
            <Link color="text.secondary">Terms of Service</Link>
          </Linker>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.instagram.com/popat_ng/"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://x.com/popat_ng"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.facebook.com/people/popat_ng/61551686385761/?mibextid=kFxxJD"
            aria-label="Facebook"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.youtube.com/channel/UCdGmBTCpmG8-lALb5UyQ1FQ"
            aria-label="Youtube"
            sx={{ alignSelf: "center" }}
          >
            <YouTubeIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.tiktok.com/@popat_"
            aria-label="TikTok"
            sx={{ alignSelf: "center" }}
          >
            {/* <TwitterIcon /> */}
            <FontAwesomeIcon icon={faTiktok} />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/popat-company-limited/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}

import React, {useEffect} from 'react';
// import QRCode from 'qrcode.react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faYoutube, faLinkedin, faTiktok, faInstagram } from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
        <div className="fixed bottom-0 left-0 right-0 p-4">
            <div className="flex items-center justify-center">
                <a href="https://x.com/popat_ng" target="_blank" rel="noopener noreferrer" className="text-white mr-6 hover:text-yellow-500">
                    <FontAwesomeIcon icon={faTwitter} size="1x" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=61551686385761&amp;mibextid=kFxxJD" target="_blank" rel="noopener noreferrer" className="text-white mr-6 hover:text-yellow-500">
                    <FontAwesomeIcon icon={faFacebook} size="1x" />
                </a>
                <a href="https://www.instagram.com/popat_ng" target="_blank" rel="noopener noreferrer" className="text-white mr-6 hover:text-yellow-500">
                    <FontAwesomeIcon icon={faInstagram} size="1x" />
                </a>
                <a href="https://www.tiktok.com/@popat_" target="_blank" rel="noopener noreferrer" className="text-white mr-6 hover:text-yellow-500">
                    <FontAwesomeIcon icon={faTiktok} size="1x"/>
                </a>
                <a href="https://www.youtube.com/channel/UCdGmBTCpmG8-lALb5UyQ1FQ" target="_blank" rel="noopener noreferrer" className="text-white mr-6 hover:text-yellow-500">
                    <FontAwesomeIcon icon={faYoutube} size="1x"/>
                </a>
                <a href="https://www.linkedin.com/company/popat-company-limited/" target="_blank" rel="noopener noreferrer" className="text-white mr-6 hover:text-yellow-500">
                    <FontAwesomeIcon icon={faLinkedin} size="1x"/>
                </a>
            </div>
            <p className="text-white mt-2 text-center">©2024 Popat Company Limited.</p>
        </div>
    );
}

export default Footer;


import React, { useState, useEffect } from "react";
import "./MainContent.css";
import Card from "./Card";
import { useSelector, useDispatch } from "react-redux";
import { ListingDataInfo } from "../Slice/FeedSlice";
import SimpleImageSlider from "react-simple-image-slider";
import Tabs from "@mui/joy/Tabs";
import TabList from "@mui/joy/TabList";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import Highlight from "./Highlight";
import PopEvents from "./highlight/PopEvents";

const MainContent = () => {
  const [events, setEvents] = useState([]);

  const dispatch = useDispatch();
  const getListingsApi = useSelector(
    (state) => state?.FeedListingSlice?.ListingInfo?.data?.results
  );

  useEffect(() => {
    dispatch(ListingDataInfo());
  }, [dispatch]);

  useEffect(() => {
    if (getListingsApi) {
      setEvents(getListingsApi);
    }
  }, [getListingsApi]);

  const [selected, setSelected] = useState("Featured");

  // console.log(events);
  const images = [
    {
      url: "https://popat-files.s3.eu-north-1.amazonaws.com/media/posts/TDB.png",
    },
    {
      url: "https://popat-files.s3.amazonaws.com/media%2Fposts%2FIMG_0259.PNG",
    },
    {
      url: "https://popat-files.s3.eu-north-1.amazonaws.com/media/posts/AfterWork+Karaoke-min.png",
    },
  ];

  return (
    <div className="main-content">
      <div className="part-a">
        <Highlight />
        <h2
          style={{
            color: "black",
            fontSize: 25,
            marginTop: 30,
            fontWeight: "bold",
          }}
        >
          For You
        </h2>

        <div className="w-full">
          <PopEvents />
        </div>

        {/* events part */}
        {/* {events && events.length > 0 ? (
          <div className="horizontal-cards">
            {events.map((item) => (
              <div key={item.id} className="horizontal-card">
                <Card
                  item={item}
                  imageUrl={item.photo}
                  avatarUrl={item.author.image}
                  title="Your Title"
                />
              </div>
            ))}
          </div>
        ) : (
          <div style={{ marginTop: "40%", textAlign: "center" }}>
            <div style={{ paddingTop: "10px", paddingBottom: "15px" }}>
              <p className="oops">Oops! No pops yet.</p>
              <p className="oops">Why don't you create one?</p>
            </div>
            <div style={{ margin: "0 20px" }}>
              
            </div>
          </div>
        )} */}
      </div>
      {/* <div className="part-b">
        <h2>Featured Creators</h2>
      </div> */}
    </div>
  );
};

export default MainContent;

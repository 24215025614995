import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAccessToken } from '../../../Authentication/tokenStorage';
import '../Dashboard.css';
import Header from '../Header';
import Sidebar from '../Sidebar';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import ScheduleIcon from '@mui/icons-material/Schedule';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import LiquorIcon from '@mui/icons-material/Liquor';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import DiamondIcon from '@mui/icons-material/Diamond';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import BiotechIcon from '@mui/icons-material/Biotech';
import StorefrontIcon from '@mui/icons-material/Storefront';
import MuseumIcon from '@mui/icons-material/Museum';

const EventDetails = () => {
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchEventDetails = async (token) => {
    console.log('Retrieved token:', token); // Log the retrieved token

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://backend.popat.io/api/pop/detail/${id}`, requestOptions);
      console.log('Server response:', response); // Log the response from the server

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Response JSON:', result); // Log the JSON response

      // Log the data structure to verify its content
      console.log('Event data:', result);

      setEvent(result);
    } catch (error) {
      console.error('Fetch error:', error); // Log any errors
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = getAccessToken();
    console.log('Token retrieved from tokenStorage:', token); // Log the token retrieved

    if (token) {
      fetchEventDetails(token);
    } else {
      setLoading(false);
    }
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day}${getDaySuffix(day)} ${month} ${year}`;
  };

  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const calculateTimeAgo = (dateString) => {
    const postedDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = currentDate - postedDate;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const monthsDiff = currentDate.getMonth() - postedDate.getMonth() + 
                        (12 * (currentDate.getFullYear() - postedDate.getFullYear()));

    if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff > 1 ? 's' : ''} ago`;
    } else {
      return `${daysDiff} day${daysDiff > 1 ? 's' : ''} ago`;
    }
  };

  const renderCategory = (category) => {
    switch (category) {
      case 'MUS':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <MusicNoteIcon />
            <span className='text-black text-center text-sm'>Nigthlife</span>
          </div>
        );
      case 'PRT':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <LiquorIcon />
            <span className='text-black text-center text-sm'>Parties</span>
          </div>
        );
        case 'HNG':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <Diversity1Icon />
            <span className='text-black text-center text-sm'>Hangouts</span>
          </div>
        );
      case 'GAM':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <SportsEsportsIcon />
            <span className='text-black text-center text-sm'>Gaming</span>
          </div>
        ); 
      case 'FND':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <LocalDiningIcon />
            <span className='text-black text-center text-sm'>Food and Drinks</span>
          </div>
        );
        case 'CLB':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <LocalBarIcon />
            <span className='text-black text-center text-sm'>Clubbing</span>
          </div>
        );
        case 'FSH':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <DiamondIcon />
            <span className='text-black text-center text-sm'>Fashion</span>
          </div>
        );
        case 'ART':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <SmartToyIcon />
            <span className='text-black text-center text-sm'>Art Show</span>
          </div>
        );
        case 'TEC':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <BiotechIcon />
            <span className='text-black text-center text-sm'>Tech</span>
          </div>
        );
        case 'TDF':
        return (
          <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
            <StorefrontIcon />
            <span className='text-black text-center text-sm'>Tradefair</span>
          </div>
        );
        case 'EXH':
          return (
            <div className='flex flex-col items-center gap-2 text-[#08A67B] p-3 bg-gray-200 rounded-t-xl'>
              <MuseumIcon />
              <span className='text-black text-center text-sm'>Exhibition</span>
            </div>
          );
        
      
      
      default:
        return <span>{category}</span>;
    }
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content-container bg-white">
        <Header />
        <div className='flex items-start justify-between bg-white gap-6 p-10'>
          <div className='w-2/3 bg-white rounded-3xl py-6 px-10 shadow-lg '>
                {event ? (
                  <div className='w-full flex flex-col items-center justify-center gap-7'>
                    <img src={event.photo} alt={event.title} className='w-2/3 rounded-2xl'/>

                    <div className='flex items-center w-2/3 justify-between '>
                      <h1 className='font-semibold text-2xl leading-relaxed'>
                        {event.title}
                      </h1>

                      <div className='flex items-center gap-4 text-gray-600'>
                        <div className='w-8 h-8'>
                          <FlagOutlinedIcon />
                        </div>

                        <div className='flex items-center gap-2 '>
                          <FavoriteBorderOutlinedIcon />
                          <p>{event.total_likes}</p>
                        </div>
                    </div>
                    </div>

                    
                    <p className='w-2/3 text-xl'>
                      {event.caption}
                    </p>
                    
                    <div className='w-2/3 flex flex-col gap-4'>
                      <div className='flex gap-2 items-center'>
                      <InsertInvitationIcon />
                      <p> {formatDate(event.date_of_event)}</p>
                      </div>

                      <div className='flex gap-2 items-center'>
                      <ScheduleIcon />
                      <p>{new Date(event.date_of_event).toLocaleTimeString()}</p>
                      </div>

                      <div className='flex gap-2 items-center'>
                      <LocationOnOutlinedIcon />
                      <p>{event.address_description}</p>
                      </div>

                      <p className='self-end text-gray-500'>-{calculateTimeAgo(event.date_posted)}</p>
                    </div>

                    <div className='w-2/3 flex items-center justify-between mt-4'>
                      {event.price ? (
                        <div>
                          <p>Price: ${event.price}</p>
                          <button className='bg-yellow-500 text-white px-4 py-2 rounded-lg'>Get Ticket</button>
                        </div>
                      ) : (
                        <div className='w-full flex flex-col gap-3 items-center justify-center'>
                          <p className=''>Free event</p>
                          <button className='bg-[#08A67B] w-full text-white px-4 py-3 rounded-full'>
                            Get Free Ticket
                          </button>
                        </div>
                      )}
                    </div>

                    <div className='w-2/3 flex flex-col gap-3'>
                      <h2>
                        Event Categories:
                      </h2>

                      <div className='flex w-full gap-4 items-start justify-between'>
                        {event.category.map((cat, index) => (
                          <div key={index}>
                            {renderCategory(cat)}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className='w-2/3 flex flex-col gap-3'>
                      <h2>
                        Creator:
                      </h2>

                      <div className='flex w-full gap-4 items-center justify-between'>
                        <img src={event.author.image} alt="" className='w-16 h-16 rounded-full' />

                        <div className='flex flex-col gap-3'>
                          <p className='text-2xl font-semibold text-[#08A67B]'>
                            @{event.author.username}
                          </p>

                          <div className='flex items-start gap-6'>
                            <div>
                              <p className='text-xl text-[#08A67B]'>
                                {event.author.followers}
                              </p>
                              <p>
                                FOLLOWERS
                              </p>
                            </div>

                            <div>
                              <p className='text-xl text-[#08A67B]'>
                              {event.author.following}
                              </p>
                              <p>
                                FOLLOWING
                              </p>
                            </div>
                          </div>
                        </div>

                        <button className='bg-[#08A67B] text-white font-medium px-8 py-2  rounded-full'>
                          View
                        </button>
                      </div>
                    </div>

                    <div className='w-2/3 flex flex-col gap-3'>
                      <h2>
                       Location:
                      </h2>

                    </div>
                  </div>
                ) : (
                  <p>Loading .....</p>
                )}
          </div>

          <div className='w-1/3 h-[600px] rounded-3xl p-6 shadow-lg'>
            <h2>Featured Creators</h2>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default EventDetails;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../Images/logo.png';
import { getEmail } from '../../utilis/getEmail'; // Importing the getEmail function

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(''); // State for email
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve the email using the getEmail function
        const savedEmail = getEmail();
        if (savedEmail) {
            setEmail(savedEmail);
        } else {
            // Redirect to the appropriate page if no email is saved
            navigate('/');
        }
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const requestData = {
                new_password: password,
                user_email: email
            };

            console.log('Data sent to server:', requestData);

            const response = await fetch('https://backend.popat.io/api/password-reset/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData)
            });

            const responseData = await response.json();
            console.log('Response from server:', responseData);

            if (response.ok) {
                console.log('Password changed successfully!');
                // Reset fields after successful submission
                setPassword('');
                navigate('/signin'); // Navigating to root route (/)
            } else {
                console.error('Failed to change password');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className='email-background w-full h-screen px-6 py-10 flex flex-col gap-6 items-center justify-center'>
            <img src={Logo} alt="" style={{ paddingTop: "1px", paddingBottom: "30px", width: "230px", height: "auto" }} />

    <form onSubmit={handleSubmit} style={{borderRadius: 25}} className='md:w-3/4 lg:w-1/2 flex flex-col gap-4 bg-white rounded items-center p-12 shadow-xl'>
        <div className='flex flex-col gap-2 items-center w-full'>
            <h1 className='text-xl font-semibold'>
                Verification successful!
            </h1>

                    <p className='md:w-2/3 text-center'>
                        Please enter a new password and login
                    </p>
                </div>

                <div className='w-full flex flex-col gap-1'>
                    <label htmlFor="password" className='text-[#6B7588]'>
                        Enter new password
                    </label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                    />
                </div>

                <button type="submit" className='w-full p-3 rounded-full text-white font-semibold bg-[#08A67B]'>
                    Continue
                </button>
            </form>
        </div>
    );
};

export default ChangePassword;

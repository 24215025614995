import React, { useState, useRef } from 'react';
import Logo from '../../Images/logo.png';
import { useParams, useNavigate } from 'react-router-dom';

const VerificationCode = () => {
    const [otpcode, setOtpcode] = useState(['', '', '', '', '', '']);
    const [verificationMessage, setVerificationMessage] = useState("Please check your mail for reset link");
    const inputRefs = useRef([]);
    const navigate = useNavigate();
    const { email } = useParams();

    const handleInputChange = (index, value) => {
        const newOtp = [...otpcode];
        newOtp[index] = value;
        setOtpcode(newOtp);
        // Move focus to the next input field if a digit is entered
        if (value && index < 5) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, event) => {
        // Move focus to the previous input field if backspace is pressed on an empty input
        if (event.key === 'Backspace' && !otpcode[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const otp = otpcode.join('');
            console.log('OTP:', otp);

            const response = await fetch('https://backend.popat.io/api/password-reset/verify-otp/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    otp: otp,
                    user_email: email
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to verify OTP');
            }

            console.log('OTP verified successfully');

            const data = await response.json();
            console.log('Response:', data); // Log the response data

            // Update verification message based on the response
            setVerificationMessage(data.message);

            // Redirect to change password page
            navigate('/change-password');

            // Reset fields after submission
            setOtpcode(['', '', '', '', '', '']);
            // Set focus back to the first input field
            inputRefs.current[0].focus();
        } catch (error) {
            console.error('Error verifying OTP:', error.message);
            // Handle error state or display error message to the user
        }
    };

    return (
        <div className='email-background w-full h-screen flex flex-col gap-6 items-center justify-center p-6'>
                <img src={Logo} alt="" style={{ paddingTop: "1px", paddingBottom: "30px", width: "230px", height: "auto" }} />

            <form onSubmit={handleSubmit} style={{borderRadius: 25}}className='md:w-3/4 lg:w-1/2 flex flex-col gap-4 bg-white rounded items-center lg:p-12 p-10 shadow-xl'>
                <div className='flex flex-col gap-2 items-center w-full'>
                    <h1 className='text-xl text-center md:text-2xl font-semibold'>
                        {verificationMessage}
                    </h1>
                </div>

                <div style={{alignItems: "center", alignSelf: "center"}}className='lg:w-1/2 flex justify-center items-center gap-1'>
                    {otpcode.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={digit}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            ref={(ref) => (inputRefs.current[index] = ref)}
                            className='border border-[#C7C9D9] outline-0 p-1 md:p-4 rounded text-[#6B7588] w-8 md:w-12 text-center'
                        />
                    ))}
                </div>

                <p className='text-center md:w-3/4'>
                    We sent a verification code to
                    <strong><span className='ml-1 mr-1 text-[#08A67B]'>
                        {email}.
                    </span> </strong>
                    <br/>
                    Please check your inbox.
                </p>

                <h1 className='text-[#08A67B] text-xl font-semibold'>
                    Resend OTP
                </h1>

                <button type="submit" className='w-full  p-3 rounded-full text-white font-semibold bg-[#08A67B]'>
                    Continue
                </button>
            </form>
        </div>
    );
};

export default VerificationCode;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

const items = [
  {
    // icon: <EdgesensorHighRoundedIcon />,
    title: "Create event",
    link: "create",
  },
  {
    title: "Explore events",
    link: "explore",
    button: "Explore",
  },
  {
    title: "Request a Demo",
    link: "calendly",
    button: "request-demo",
  },
];

export default function CallToAction() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container
      id="start"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <div>
        <Typography
          component="h2"
          variant="h2"
          color="text.primary"
          sx={{
            width: { sm: "100%", md: "100%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          Ready to get started?
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ mb: { xs: 2, sm: 4 } }}
        ></Typography>
      </div>
      <Grid
        container
        item
        gap={1}
        sx={{
          width: { sm: "20%", md: "20%" },
          display: { xs: "auto", sm: "none" },
          // flexDirection: "column",
          // alignItems: "flex-start",
        }}
      >
        {items.map(({ title, link }, index) => (
          <Link
            key={index}
            href={link}
            underline="none" // This removes the underline from the link text
            sx={{ textDecoration: "none", display: "inline-flex" }}
          >
            <Chip
              label={title}
              onClick={() => handleItemClick(index)}
              sx={{
                borderColor: (theme) => {
                  if (theme.palette.mode === "light") {
                    return selectedItemIndex === index ? "primary.light" : "";
                  }
                  return selectedItemIndex === index ? "primary.light" : "";
                },
                background: (theme) => {
                  if (theme.palette.mode === "light") {
                    return selectedItemIndex === index ? "none" : "";
                  }
                  return selectedItemIndex === index ? "none" : "";
                },
                backgroundColor:
                  selectedItemIndex === index ? "primary.main" : "",
                "& .MuiChip-label": {
                  color: selectedItemIndex === index ? "#fff" : "",
                },
              }}
            />
          </Link>
        ))}
      </Grid>

      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
        useFlexGap
        sx={{ width: "25%", display: { xs: "none", sm: "flex" } }}
      >
        {items.map(({ icon, title, description, button, link }, index) => (
          <Card
            key={index}
            variant="outlined"
            component={Button}
            onClick={() => handleItemClick(index)}
            sx={{
              p: 3,
              height: "fit-content",
              width: "100%",
              background: "none",
              backgroundColor:
                selectedItemIndex === index ? "action.selected" : undefined,
              borderColor: (theme) => {
                if (theme.palette.mode === "light") {
                  return selectedItemIndex === index
                    ? "primary.light"
                    : "grey.200";
                }
                return selectedItemIndex === index
                  ? "primary.dark"
                  : "grey.800";
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                textAlign: "left",
                flexDirection: { xs: "column", md: "row" },
                alignItems: { md: "center" },
                gap: 2.5,
              }}
            >
              <Box sx={{ textTransform: "none" }}>
                <Link
                  color="primary"
                  variant="body2"
                  fontWeight="bold"
                  href={link}
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    "& > svg": { transition: "0.2s" },
                    "&:hover > svg": { transform: "translateX(2px)" },
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <Typography
                    color="text.primary"
                    variant="h5"
                    fontWeight="bold"
                  >
                    {title}
                  </Typography>
                  <ChevronRightRoundedIcon
                    fontSize="small"
                    sx={{ mt: "1px", ml: "2px" }}
                  />
                </Link>
              </Box>
            </Box>
          </Card>
        ))}
      </Stack>
      {/* <Grid
        item
        xs={12}
        md={6}
        sx={{ display: { xs: "none", sm: "flex" }, width: "100%" }}
      >
        <Card
          variant="outlined"
          sx={{
            height: "100%",
            width: "100%",
            display: { xs: "none", sm: "flex" },
            pointerEvents: "none",
          }}
        >
          <Box
            sx={{
              m: "auto",
              width: "100%",
              height: "100%",
              backgroundSize: "contain",
              backgroundImage: (theme) =>
                theme.palette.mode === "light"
                  ? items[selectedItemIndex].imageLight
                  : items[selectedItemIndex].imageDark,
            }}
          />
        </Card>
      </Grid> */}
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import { getAccessToken } from "../../../../pages/Authentication/tokenStorage"; // Adjust the import path as needed

const ClosedTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getAccessToken();
    console.log("Retrieved token:", token); // Log the retrieved token

    if (!token) {
      console.error("No access token found");
      setLoading(false);
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://backend.popat.io/api/estore/t-summary/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        setTickets(result.closed_orders || []); // Update to use the correct key
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full">
      {tickets.length > 0 ? (
        <div className="flex flex-col gap-1 items-center w-full">
          <p className="text-slate-500">We are currently working on this!</p>
          <p className="text-slate-600 font-semibold text-lg">
            Check back shortly.
          </p>
        </div>
      ) : (
        <div>No ticket available</div>
      )}
    </div>
  );
};

export default ClosedTickets;

import React, { useState } from 'react';
// import Footer from './Footer';
import rightside from "../../Images/rightside.png";
import leftside from "../../Images/leftside.png";
import Header from './Header';
import { Link } from 'react-router-dom';
import AppAppBar from '../../general/components/AppAppBar';
import FooterWhite from "../../general/components/FooterWhite";

const Terms = () => {

    const [activeLink, setActiveLink] = useState('terms');

    const handleLinkClick = (link) => {
        setActiveLink(link);
    };

    return (
<>
        <div className="w-full h-screen flex flex-col items-center justify-center relative">
    <img src={leftside} alt="" className="image left" />
    <img src={rightside} alt="" className="image right" />

   {/* <Header/> */}
      <AppAppBar 
    //   mode={mode} toggleColorMode={toggleColorMode} 
      />


    <div className="flex flex-col items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></div>
        <div className="content-container">
            <div className="row row-a">
               <div className="nav-links text-lg lg:text-2xl">
    <a href="#terms" onClick={() => handleLinkClick('terms')} className={activeLink === 'terms' ? 'clicked' : ''}>Terms of Use</a>
    <a href="#privacy-policy" onClick={() => handleLinkClick('privacy')} className={activeLink === 'privacy' ? 'clicked' : ''}>Privacy Policy</a>
    <a href="#creators-agreement" onClick={() => handleLinkClick('merchant')} className={activeLink === 'merchant' ? 'clicked' : ''}>Creator Agreement</a>
</div>

            </div>
            <div className="row row-b">
                {/* <div id="terms-content" className={`content-section ${activeLink === 'terms' ? 'active' : ''}`}> */}
                <div class="scrollable-text" style={{paddingTop: "10px"}}>
                        {activeLink  === 'terms'  && 
                            (
                                <>
                            <h2><strong>1.1 Understanding Definitions</strong></h2>
<p>To facilitate your comprehension of these Terms, here are some key definitions:</p>
<ul>
    <li><strong>"Affiliate"</strong> refers to any individual or entity that controls, is controlled by, or is under common control with another entity. Control in this context signifies ownership or the ability to direct management and policies, either directly or indirectly, owning or controlling more than 20% of the outstanding voting stock of an entity.</li>
    <li><strong>"Consumers"</strong> denote individuals using our Services, including accessing information or participating in events organized by event creators/organizers, service apartments, and the like.</li>
    <li><strong>"Products"</strong> encompass the offerings of Popat Technologies Limited, available:
        <ul>
            <li>Online via various Popat platforms like popat.io ("Sites").</li>
            <li>Off-platform, including entry management, sponsorship, marketing, or distribution services.</li>
            <li>Through mobile applications, webpages, application programming interfaces, and subdomains ("Applications").</li>
        </ul>
    </li>
    <li><strong>"Material"</strong> encompasses a wide range of content and information, including text, images, videos, music, and other materials.</li>
    <li><strong>"Creator"</strong> signifies an individual utilizing our Services to create events for Consumers.</li>
    <li><strong>"Services"</strong> encompass all services provided by Popat Technologies Limited.</li>
    <li><strong>"Site Content"</strong> refers to the Material delivered via the Services or made available by Popat in connection with the Services.</li>
    <li><strong>"Your Content"</strong> denotes any Material contributed, posted, or made available by you through the Services or authorized for use by us.</li>
    <li><strong>"Your Trademarks"</strong> refer to the trademarks, trade names, service marks, and logos provided by you for use through the Services or authorized for use by us.</li>
    <li>Users of our Services, including Creators, Consumers, and third parties, are collectively referred to as <strong>"Users," "you,"</strong> or <strong>"your"</strong> within these Terms.</li>
</ul>

<h2>1.2 Additional Considerations</h2>
<p>Other terms may also apply to you. By accepting these Terms, you acknowledge and agree to:</p>
<ul>
    <li>The privacy policy and cookie statements applicable to all Users.</li>
    <li>Compliance with Popat community guidelines, which outline permissible content and conduct.</li>
    <li>Possible adherence to additional terms, such as:
        <ul>
            <li>Popat Merchant Agreement and Creator Refund Policy Requirements for Creators.</li>
            <li>Popat Ads Guidelines for Users accessing the Popat Ads Service.</li>
            <li>Referral Terms for participants in referral programs.</li>
            <li>API Terms of Use and/or Trademark and Copyright Policy for third-party interactions.</li>
        </ul>
    </li>
    <li>Also, be aware of additional terms displayed with certain Services you use, which will be binding. Any services provided beyond those described in these Terms will still be governed by these Terms unless specified otherwise in a separate, signed agreement.</li>
</ul>
                            
                            
                            
                            
                           </> 
                            )              
                        }
                        {activeLink  === 'privacy'  && 
                            (
                            <>
                            <div className='flex flex-col gap-3 text-sm font-medium'>
                        <span className=''>
                        Thank you for choosing to be part of our community 
                        at Popat Company Limited, doing business as Popat 
                        ("We", "Us", or "our”). We are committed to protecting 
                        your personal information and your right to privacy. 
                        If you have any questions or concerns about this privacy 
                        notice or our practices with regard to your personal 
                        information, please contact us at hello@popat.io
                        </span>

                        <div className='flex flex-col gap-2'>
                            <span>
                            This privacy notice describes how we might use your information if you:
                            </span>

                            <span className='ml-3'>
                            Download and use our mobile application – Popat
                            </span>

                            <span className='ml-3'>
                            Engage with us in other related ways - including any sales, marketing, events
                            </span>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            In this privacy notice, if we refer to:
                            </span>

                            <span className='ml-3'>
                            "App," we are referring to any application of ours that references or links to this policy, including any listed above.
                            </span>

                            <span className='ml-3'>
                            "Services," we are referring to our App, and other related services, including any sales, marketing, or events
                            </span>
                        </div>

                        <span className=''>
                        The purpose of this privacy notice is to explain to you 
                        in the clearest way possible what information we collect, 
                        how we use it, and what rights you have in relation to it. 
                        If there are any terms in this privacy notice that you do not agree with, 
                        please discontinue use of our Services immediately. Please read this privacy 
                        notice carefully, as it will help you understand what we do with the information that we collect.
                        </span>

                        <div className='flex flex-col gap-2'>
                            <span>
                            1. WHAT INFORMATION DO WE COLLECT?
                            </span>

                            <span className=''>
                            Personal information you disclose to us
                            </span>

                            <span className=''>
                            We collect personal information that you provide to us. 
                            We collect personal information that you voluntarily provide 
                            to us when you register on the App, express an interest in 
                            obtaining information about us or our products and Services, 
                            when you participate in activities on the App 
                            (such as by posting messages in our online forums or entering competitions, contests or giveaways) 
                            or otherwise when you contact us. The personal information that we collect depends on the context 
                            of your interactions with us and the App, the choices you make and the products and features you use. 
                            The personal information we collect may include the following: Personal Information Provided by You. 
                            We collect names; phone numbers; email addresses; usernames; passwords; and other similar information. 
                            Payment Data. We may collect data necessary to process your payment if you make purchases, 
                            such as your payment instrument number (such as a credit card number), and the security code 
                            associated with your payment instrument. All payment data is stored by Paystack. 
                            You may find their privacy notice link(s) here:
                            <Link to="" className='text-blue-700 mr-2'> https://paystack.com/terms</Link> 
                            
                            <br></br>
                            <span>
                                </span>Social Media Login Data. 
                            We may provide you with the option to register with us using your existing social media account details, 
                            like your Facebook, Twitter or other social media account. If you choose to register in this way, 
                            we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS? 
                            below. All personal information that you provide to us must be true, complete and accurate, 
                            and you must notify us of any changes to such personal information automatically collected. 
                            Some information - such as your Internet Protocol (IP) address and/or browser and device 
                            characteristics-- is collected automatically when you visit our App. We automatically 
                            collect certain information when you visit, use or navigate the App. This information 
                            does not reveal your specific identity (like your name or contact information) 
                            but may include device and usage information, such as your IP address, browser 
                            and device characteristics, operating system, language preferences, referring URLs, 
                            device name, country, location, information about how and when you use our App 
                            and other technical information. This information is primarily needed to maintain 
                            the security and operation of our App, and for our internal analytics and reporting 
                            purposes Like many businesses, we also collect information through cookies and similar 
                            technologies. The information we collect includes: Log and Usage Data. Log and usage 
                            data is service-related, diagnostic, usage and performance information our servers 
                            automatically collect when you access or use our App and which we record in log files. 
                            Depending on how you interact with us, this log data may include your IP address, device 
                            information, browser type and settings and information about your activity in the App 
                            (such as the date/time stamps associated with your usage, pages and files viewed, searches 
                            and other actions you take such as which features you use), device event information (such 
                            as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
                            Device Data. We collect device data such as information about your computer, phone, 
                            tablet or other device you use to access the App. Depending on the device used, 
                            this device data may include information such as your IP address (or proxy server), 
                            device and application identification numbers, location, browser type, hardware model 
                            Internet service provider and/or mobile carrier, operating system and system configuration 
                            information Location Data. We collect location data such as information about your device's 
                            location, which can be either precise or imprecise. How much information we collect depends 
                            on the type and settings of the device you use to access the App. For example, we may use GPS 
                            and other technologies to collect geolocation data that tells us your current location 
                            (based on your IP address). You can opt out of allowing us to collect this information 
                            either by refusing access to the information or by disabling your Location setting on your device. 
                            Note however, if you choose to opt out, you may not be able to use certain aspects of the Services. 
                            Information collected through our App We collect information regarding your geolocation, 
                            mobile device, push notifications, when you use our App. If you use our App,
                             we also collect the following information: Geolocation Information. 
                             We may request access or permission to and track location-based information 
                             from your mobile device, either continuously or while you are using our App, 
                             to provide certain location-based services. If you wish to change our access 
                             or permissions, you may do so in your device's settings. Mobile Device Access. 
                             We may request access or permission to certain features from your mobile device, 
                             including your mobile device's calendar, camera, reminders, social media accounts, 
                             storage, contacts, and other features. If you wish to change our access or permissions 
                             you may do so in your device's settings. Push Notifications. We may request to send you 
                             push notifications regarding your account or certain features of the App. if you wish 
                             to opt-out from receiving these types of communications; you may turn them off in your 
                             device's settings. This information is primarily needed to maintain the security and 
                             operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
                            </span>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            2. HOW DO WE USE YOUR INFORMATION?
                            </span>

                            <span className=''>
                            We process your information for purposes based on legitimate business interests, 
                            the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
                            </span>

                            <span className=''>
                            We use personal information collected via our App for a variety 
                            of business purposes described below. We process your personal 
                            information for these purposes in reliance on our legitimate business 
                            interests, in order to enter into or perform a contract with you, 
                            with your consent, and/or for compliance with our legal obligations. 
                            We indicate the specific processing grounds we rely on next to each purpose listed below.
                            </span>

                            <span className=''>
                            We use the information we collect or receive:
                            </span>

                            <span className=''>
                            To facilitate account creation and logon process. 
                            If you choose to link your account with us to a third-party 
                            account (such as your Google or Facebook account), 
                            we use the information you allowed us to collect from those 
                            third parties to facilitate account creation and logon process 
                            for the performance of the contract. See the section below headed 
                            HOW DO WE HANDLE YOUR SOCIALLOGINS?* for further information.
                            </span>

                            <span className=''>
                            To post testimonials. We post testimonials on our App that may contain personal information. 
                            Prior to posting a testimonial, we will obtain your consent to use your name and the content 
                            of the testimonial. If you wish to update, or delete your testimonial, please contact us at 
                            hello@popat.io and be sure to include your name, testimonial location, and contact information.
                            Request feedback. We may use your information to request feedback and to contact you about 
                            your use of our App              
                            </span>

                            <span className=''>
                            To enable user-to-user communications. We may use your information in order 
                            to enable user-to-user communications with each user's consent.             
                            </span>

                            <span className=''>
                            All personal information that you provide to us must be true, complete and accurate, 
                            and you must notify us of any changes to such personal information
                            </span>

                            <span className=''>
                            To manage user accounts. We may use your information for the purposes 
                            of managing our account and keeping it in working order.
                            </span>

                            <span className=''>
                            Fulfill and manage your orders. We may use your information to fulfill 
                            and manage your orders, payments, returns, and exchanges made through the App.
                            </span>

                            <span className=''>
                            Administer prize draws and competitions. We may use your information to 
                            administer prize draws and competitions when you elect to participate in our competitions.
                            </span>

                            <span className=''>
                            To deliver and facilitate delivery of services to the user. 
                            We may use your information to provide you with the requested service.
                            </span>

                            <span className=''>
                            To respond to user inquiries/offer support to users. We may use your 
                            information to respond to your inquiries and solve any potential issues you 
                            might have with the use of our Services
                            </span>

                            <span className=''>
                            To send you marketing and promotional communications. 
                            We and/or Our third-party marketing partners may use the 
                            personal information you send to us for our marketing purposes, 
                            if this is in accordance with your marketing preferences. 
                            For example, when expressing an interest in obtaining information about 
                            us or our App, subscribing to marketing or otherwise contacting us, 
                            we will collect personal information from you. You can opt-out of our
                            marketing emails at any time (see the WHAT ARE YOUR PRIVACY RIGHTS? below).
                            </span>

                            <span className=''>
                            Deliver targeted advertising to you. We may use your information to 
                            develop and display personalized content and advertising (and work with third parties who do so) 
                            tailored to your interests and/or location and to measure its effectiveness.
                            </span>

                            <span className=''>
                            For other business purposes. We and/or Our third-party marketing partners may use the personal 
                            information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. 
                            For example, when expressing an interest in obtaining information about us or our App, 
                            subscribing to marketing or otherwise contacting us, we will collect personal information from you. 
                            You can opt-out of our marketing emails at any time (see the WHAT ARE YOUR PRIVACY RIGHTS? below).
                            </span>

                            <span className=''>
                            To send you marketing and promotional communications. We may use your 
                            information for other business purposes, such as data analysis, 
                            identifying usage trends, determining the effectiveness of our promotional 
                            campaigns and to evaluate and improve our App, products, marketing and your experience. 
                            We may use and store this information in aggregated and anonymized form so that it 
                            is not associated with individual end users and does not include personal information.
                            </span>

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                            </span>

                            <span>
                            We only share information with your consent, to comply with laws, 
                            to provide you with services, to protect your rights, or to fulfill business obligations.
                            </span>

                            <span>
                            We may process or share your data that we hold based on the following legal basis:
                            </span>

                            <span>
                            Consent: We may process your data if you have given us specific consent to use your 
                            personal information for a specific purpose.
                            </span>

                            <span>
                            Legitimate Interests: We may process your data when it is reasonably 
                            necessary to achieve our legitimate business interests.
                            </span>

                            <span>
                            Performance of a Contract: Where we have entered into a contract with you, 
                            we may process your personal information to fulfill the terms or our contract.
                            </span>

                            <span>
                            Legal Obligations: We may disclose your information where we are legally 
                            required to do so in order to comply with applicable law, governmental requests, 
                            a judicial proceeding, court order, or legal process, such as in response to a 
                            court order or a subpoena (including in response to public authorities to meet 
                            national security or law enforcement requirements).
                            </span>

                            <span>
                            Vital Interests: We may disclose your information where we believe it is necessary to 
                            investigate, prevent, or take action regarding potential violations of our policies, 
                            suspected fraud, situations involving potential threats to the safety of any person and 
                            illegal activities, or as evidence in litigation in which we are involved.
                            </span>

                            <span>
                            More specifically, we may need to process your data or share your personal information in the following situations:
                            </span>

                            <span>
                            Business Transfers. We may share or transfer your information in connection with, 
                            or during negotiations of, any merger, sale of company assets, financing, or 
                            acquisition of all or a portion of our business to another company.
                            </span>

                            <span>
                            Vital Interests: We may disclose your information where we believe it is necessary to 
                            investigate, prevent, or take action regarding potential violations of our policies, 
                            suspected fraud, situations involving potential threats to the safety of any person and 
                            illegal activities, or as evidence in litigation in which we are involved.
                            </span>

                            <span>
                            Google Maps Platform APIs. We may share your information with certain Google Maps 
                            Platform APIs (e g. Google Maps API, Place API). To find out more about Google's 
                            Privacy Policy, please refer to this link. We use certain Google Maps Platform APIs 
                            to retrieve certain information when you make location-specific requests. 
                            This includes User's location, and other similar information. 
                            A full list of what we use information for can be found in this section 
                            and in the previous section titled HOW DO WE USE YOUR INFORMATION?" 
                            The Google Maps Platform APIs that we use store and access cookies 
                            and other information on your devices. If you are a user currently 
                            in the European Economic Area (EU countries, Iceland, Liechtenstein and Norway) 
                            or United Kingdom, please take a look at our Cookie Notice.
                            </span>

                            <span>
                            Business Partners. We may share your information with our business 
                            partners to offer you certain products, services or promotions.
                            </span>

                            <span>
                            Other Users. When you share personal information (for example, by posting comments, 
                            contributions of other content to the App) or otherwise interact with public areas 
                            of the App, such personal information may be viewed by all users and may 
                            be publicly made available outside the App in perpetuity. If you interact 
                            with other users of our App and register for our App through a social 
                            network (such as Facebook), your contacts on the social network will 
                            see your name, profile photo, and descriptions of your activity. Similarly, 
                            other users will be able to view descriptions of your activity, communicate with 
                            you within our App, and view your profile.
                            </span>

                            <span>
                            Offer Wall. Our App may display a third-party hosted "offer wall." Such an offer 
                            wall allows third-party advertisers to offer virtual currency, gifts, or other 
                            items to users in return for the acceptance and completion of an advertisement 
                            offer. Such an offer wall may appear in our App and be displayed to you based on 
                            certain data, such as your geographic area or demographic information. 
                            When you click on an offer wall, you will be brought to an external website 
                            belonging to other persons and will leave our App. A unique Identifier, 
                            such as your user ID, will be shared with the offer wall provider in order 
                            to prevent fraud and property credit your account with the relevant reward. 
                            Please note that we do not control third-party websites and have no responsibility 
                            in relation to the content of such websites. The inclusion of a link towards a 
                            third-party website does not imply an endorsement by us of such website. 
                            Accordingly, we do not make any warranty regarding such third-party websites and we will not be 
                            liable for any loss or damage caused by the use of such websites. In addition, 
                            when you access any third-party website, please understand that your rights 
                            while accessing and using those websites will be governed by the privacy notice 
                            and terms of service relating to the use of those websites.
                            </span>

                           

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                            </span>

                            <span className=''>
                            We may use cookies and other tracking technologies to collect and store your information.
                            </span>

                            <span className=''>
                            We may use cookies and similar tracking technologies (like web beacons and pixels) 
                            to access or store information. Specific information about how we use such technologies 
                            and how you can refuse certain cookies is set out in our Cookie Notice.
                            </span>

                            <span className=''>
                            If you choose to register or log in to our services using a social media account, 
                            we may have access to certain information about you. Our App offers you the ability 
                            to register and login using your third-party social media account details 
                            (like your Facebook or Twitter logins). Where you choose to do this, we will 
                            receive certain profile information about you from your social media provider. 
                            The profile information we receive may vary depending on the social media provider 
                            concerned, but will often include your name, mall address, friends list, 
                            profile picture as well as other information you choose to make public on such social media platform.
                            </span>

                            <span className=''>
                            We will use the information we receive only for the purposes that 
                            are described in this privacy notice or that is otherwise made clear to you on the 
                            relevant App. Please note that we do not control, and are not responsible for, 
                            other uses of your personal information by your third-party social media provider. 
                            We recommend that you review their privacy notice to understand how they collect use 
                            and share your personal information, and how you can set your privacy preferences on 
                            their sites and apps.
                            </span>

                            

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            5. HOW LONG DO WE KEEP YOUR INFORMATION?
                            </span>

                            <span className=''>
                            We may use cookies and other tracking technologies to collect and store your information.
                            </span>

                            <span className=''>
                            We may use cookies and similar tracking technologies (like web beacons and pixels) 
                            to access or store information. Specific information about how we use such technologies 
                            and how you can refuse certain cookies is set out in our Cookie Notice.
                            </span>

                            <span className=''>
                            If you choose to register or log in to our services using a social media account, 
                            we may have access to certain information about you. Our App offers you the ability 
                            to register and login using your third-party social media account details 
                            (like your Facebook or Twitter logins). Where you choose to do this, we will 
                            receive certain profile information about you from your social media provider. 
                            The profile information we receive may vary depending on the social media provider 
                            concerned, but will often include your name, mall address, friends list, 
                            profile picture as well as other information you choose to make public on such social media platform.
                            </span>

                            <span className=''>
                            We will use the information we receive only for the purposes that 
                            are described in this privacy notice or that is otherwise made clear to you on the 
                            relevant App. Please note that we do not control, and are not responsible for, 
                            other uses of your personal information by your third-party social media provider. 
                            We recommend that you review their privacy notice to understand how they collect use 
                            and share your personal information, and how you can set your privacy preferences on 
                            their sites and apps.
                            </span>

                            <span className=''>
                            We keep your information for as long as necessary to fulfill the purposes outlined 
                            in this privacy notice unless otherwise required by law.
                            </span>

                            <span className=''>
                            We will only keep your personal information for as long as it is necessary for 
                            the purposes set out in this privacy notice, unless a longer retention period is 
                            required or permitted by law (such as tax, accounting or other legal requirements). 
                            No purpose in this notice will require us keeping your personal information for longer 
                            than the period of time in which users have an account with us.
                            </span>

                            <span className=''>
                            When we have no ongoing legitimate business need to process your personal information, 
                            we will either delete or anonymize such information, or, if this is not possible 
                            (for example, because your personal information has been stored in backup archives), 
                            then we will securely store your personal information and isolate it from any further 
                            processing until deletion is possible.
                            </span>

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            7. HOW DO WE KEEP YOUR INFORMATION SAFE?
                            </span>

                            <span className=''>
                            We aim to protect your personal information through a system of organizational 
                            and technical security measures.
                            </span>

                            <span className=''>
                            We have implemented appropriate technical and organizational security measures designed to 
                            protect the security of any personal information we process. However, despite our safeguards 
                            and efforts to secure your information, no electronic transmission over the internet or 
                            information storage technology can be guaranteed to be 100% secure, so we cannot promise 
                            or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be 
                            able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our App is at your own risk. You should only access the App within a secure environment.
                            </span>

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            8. WHAT ARE YOUR PRIVACY RIGHTS?
                            </span>

                            <span className=''>
                            You may review, change, or terminate your account at any time.
                            </span>

                            <span className=''>
                            If you have questions or comments about your privacy rights, you may email us at hello@popat.io
                            </span>

                            <span className=''>
                            Account Information. If you would at any time like to review or change the information 
                            in your account or terminate your account, you can:
                            </span>

                            <span className=''>
                            Log in to your account settings and update your user account
                            </span>

                            <span className=''>
                            Upon your request to terminate your account, we will deactivate or delete your 
                            account and information from our active databases. However, we may retain some 
                            information in our files to prevent fraud, troubleshoot problems, assist with any 
                            investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
                            </span>

                            <span className=''>
                            Opting out of email marketing: You can unsubscribe from our marketing 
                            email list at any time by clicking on the unsubscribe link in the emails 
                            that we send or by contacting us using the details provided below. 
                            You will then be removed from the marketing email list however, 
                            we may still communicate with you, for example to send you service-related 
                            emails that are necessary for the administration and use of your account, 
                            to respond to service requests, or for other non-marketing purposes. 
                            To otherwise opt-out, you may. Contact us using the contact information provided.
                            </span>

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            9. CONTROLS FOR DO-NOT-TRACK FEATURES
                            </span>

                            <span className=''>
                            Most web browsers and some mobile operating systems and mobile applications 
                            include a Do-Not-Track (DNT) feature or setting you can activate to signal 
                            your privacy preference not to have data about your online browsing activities 
                            monitored and collected. At this stage no uniform technology standard for 
                            recognizing and implementing DNT signals has been finalized. As such, 
                            we do not currently respond to DNT browser signals or any other mechanism 
                            that automatically communicates your choice not to be tracked online. 
                            If a standard for online tracking is adopted that we must follow in the future, 
                            we will inform you about that practice in a revised version of this privacy notice.
                            </span>

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            10. DO WE MAKE UPDATES TO THIS NOTICE?
                            </span>

                            <span className=''>
                            Yes, we will update this notice as necessary to stay compliant with relevant laws
                            </span>

                            <span className=''>
                            We may update this privacy notice from time to time. 
                            The updated version will be indicated by an updated "Revised" 
                            date and the updated version will be effective as soon as it is accessible. 
                            If we make material changes to this privacy notice, we may notify you either 
                            by prominently posting a notice of such changes or by directly sending you a 
                            notification. We encourage you to review this privacy notice frequently to be 
                            informed of how we are protecting your information.
                            </span>

                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </span>

                            <span className=''>
                            If you have questions or comments about this notice, you may email us at hello@popat.io
                            </span>
                        </div>

                        <div className='flex flex-col gap-2'>
                            <span>
                            12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                            </span>

                            <span className=''>
                            Based on the applicable laws of your country, 
                            you may have the right to request access to the personal 
                            information we collect from you, change that information, 
                            or delete it in some circumstances. To request to review, 
                            update, or delete your personal information, 
                            please submit a request form by clicking here.
                            </span>
                        </div>



                    </div>
                    </>)        
                        }
                        {activeLink  === 'merchant'  && 
                            <p>See Terms of Use</p>                 
                        }
                
                </div>
                {/* <div id="privacy-content" className={`content-section ${activeLink === 'privacy' ? 'active' : ''}`}>
                    PRIVACY POLICY CONTENT
                </div>
                <div id="merchant-content" className={`content-section ${activeLink === 'merchant' ? 'active' : ''}`}>
                    MERCHANT AGREEMENT CONTENT
                </div> */}
            </div>
        </div>
        </div>
   <div>
                    
                    <FooterWhite />
                    
                    </div>
                    </>
    );
}

export default Terms

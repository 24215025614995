import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const items = [
  {
    image:
      "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/createicon.png",
    title: "Create timeless events in seconds",
    description: "Effortlessly create unforgettable events with a few taps.",
  },
  {
    image: "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/gb.png",
    title: "Grow your Brand",
    description: "You're more than an event creator— build your brand. ",
  },
  {
    image:
      "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/discover.png",
    title: "Discover what's happening near you",
    description: "Bringing the world of events to your fingertips.",
  },
  {
    image:
      "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/scp.png",
    title: "Smart Check-in Process",
    description: "Check-in made easy! Validate tickets & manage attendees.",
  },
  {
    image:
      "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/rounded.png",
    title: "Solutions for every type of event",
    description: "Customized Solutions for every event, Big or Small.",
  },
  {
    image:
      "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/uya.png",
    title: "Stay up-to-date!",
    description:
      "Attendees will never miss an update. Send broadcast for free.",
  },
  {
    image:
      "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/payout.png",
    title: "Instant Payout!",
    description: "Receive your earnings instantly, without the wait.",
  },
  {
    image:
      "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/uya.png",
    title: "Understand your audience",
    description:
      "See real-time analytics and get better insights for your events",
  },
  // {
  //   image:
  //     "https://popat-files.s3.eu-north-1.amazonaws.com/emailAssets/hand+-+Copy.png",
  //   title: "Location-based experiences",
  //   description: "Attendees can find your events by proximity ",
  // },
];

export default function Highlights() {
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h6" color="text.secondary">
            FEATURES
          </Typography>
          <Typography component="h2" variant="h2" color="text.grey">
            All you need in one app
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="row"
                spacing={2}
                component={Card}
                sx={{
                  p: 2,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                }}
              >
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    overflow: "hidden",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    fontWeight="medium"
                    gutterBottom
                    sx={{ color: "grey.100" }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Images/logo.png';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import Footer from '../BuildPages/Footer';
import { Helmet } from 'react-helmet';
import rightside from "../../Images/rightside.png";
import leftside from "../../Images/leftside.png";
import AppleIcon from '@mui/icons-material/Apple';

const SignUp = () => {
    const [username, setUsername] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const apiUrl = 'https://backend.popat.io/api/register/';
    
        try {
            const requestData = {
                email,
                password,
                username: username.toLowerCase(), // Convert username to lowercase before sending
                otp: '000000', // Set otp value directly
                first_name: firstname,
                last_name: lastname
            };
    
            console.log('User Data Sent to Server:', requestData); // Log user data sent to server
    
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData)
            });
    
            const responseData = await response.json(); // Parse the response data
            console.log('Response from Server:', responseData); // Log response data
    
            if (!response.ok) {
                throw new Error('Sign up failed');
            }
    
            // Reset fields after successful sign up
            setUsername('');
            setFirstname('');
            setLastname('');
            setEmail('');
            setPassword('');
            setMessage('Account created. Please sign in.'); // Set success message
        } catch (error) {
            console.error('Error:', error.message);
            setMessage('Sign up failed. Please try again.'); // Set error message
        }
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Popat - Expressing and Experiencing Life.</title>
                <meta name="description" content="Discover the leading event platform in Lagos for effortless planning, promotion, and execution." />
                <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
                <meta name="robots" content="index" />
            </Helmet>

            <div className="w-full flex flex-col items-center justify-center relative">
                <img src={leftside} alt="" className="image left" />
                <img src={rightside} alt="" className="image right" />                    
                <img src={Logo} alt="" style={{ paddingTop: "70px", paddingBottom: "30px", width: "230px", height: "auto" }} />

            <form onSubmit={handleSubmit} style={{marginBottom: "40px", borderRadius: 25}} className='md:w-3/4 lg:w-1/2 flex flex-col gap-4 bg-white  items-center p-12 shadow-xl'>
                <div className='flex flex-col gap-2 items-center w-full'>
                    <h1 className='text-2xl font-semibold'>
                        Create an account
                    </h1>

                    {/* <p className='md:w-2/3 text-center'>
                        Sign up for free!
                    </p> */}

                </div>

                    <div style={{ paddingTop: 10 }} className='w-full flex flex-col gap-1'>
                        <label htmlFor="username" className='text-[#6B7588]'>Username</label>
                        <input
                            type="text"
                            id="username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value.toLowerCase())} // Convert to lowercase on change
                            className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                        />
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <label htmlFor="firstname" className='text-[#6B7588]'>First name</label>
                        <input
                            type="text"
                            id="firstname"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                        />
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <label htmlFor="lastname" className='text-[#6B7588]'>Last name</label>
                        <input
                            type="text"
                            id="lastname"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                        />
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <label htmlFor="email" className='text-[#6B7588]'>Email address</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                        />
                    </div>

                    <div className='w-full flex flex-col gap-1'>
                        <label htmlFor="password" className='text-[#6B7588]'>Enter Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='border border-[#C7C9D9] outline-0 p-3 rounded text-[#6B7588]'
                        />
                    </div>

                    <div className='w-full flex gap-4 items-center justify-between'>
                        <div className='flex gap-1'>
                            <span>Have an account?</span>
                            <Link to='/signin' className='text-[#08A67B] font-semibold'>Sign In</Link>
                        </div>
                    </div>

                    {message && <p className={message.includes('failed') ? 'text-red-500' : 'text-green-500'}>{message}</p>}

                    <button style={{ marginLeft: 100, marginRight: 100 }} type="submit" className='w-full p-3 rounded-full text-white font-semibold bg-[#08A67B]'>
                        Sign Up
                    </button>

                    <p style={{ fontSize: 13 }}>
                        By signing up, you agree to our
                        <Link to='/terms' className='ml-1 mr-1 text-[#08A67B]'>terms and policies.</Link>
                        Please take a few minutes to read and understand them
                    </p>

                    <p>Or continue with</p>

                <div className='w-full flex items-center justify-between gap-4'>
                    <button className='w-1/2 flex items-center justify-center rounded-full py-3 px-4 bg-[#408EE8] text-white'>
                        <FacebookIcon /> <h3 style={{paddingLeft: 10}}>Facebook</h3>
                    </button>
                    <button className='w-1/2 flex items-center justify-center rounded-full py-3 px-4 bg-[#CB2027] text-white'>
                        <GoogleIcon /> <h3  style={{paddingLeft: 10}}>  Google</h3>
                    </button>
                    <button className='w-1/2 flex items-center justify-center rounded-full py-3 px-4 bg-[#000000] text-white'>
                        <AppleIcon /> <h3  style={{paddingLeft: 10}}>  Apple</h3>
                    </button>
                </div>
            </form>
        </div>
        </>
    )
}

export default SignUp;

import React, { useState, useEffect } from "react";
import "./Notifications.css";
import { IoIosNotificationsOutline } from "react-icons/io";
import DialogContent from '@mui/joy/DialogContent';

const Notifications = ({ setOpenNotificationModal }) => {
  // const [show, setShow] = useState(false);

  // useEffect(() => {
  //   setShow(true);
  // }, []);

  // const handleClick = (event) => {
  //   if (event.target === event.currentTarget) {
  //     setShow(false);
  //     setTimeout(() => setOpenNotificationModal(false), 300);
  //   }
  // };

 return (
   
      <div className="h-full overflow-auto">
        <div className="flex flex-col gap-y-3">
          <NotificationItem />
          <NotificationItem />
          <NotificationItem />
      
        </div>
      </div>
  );
};

export default Notifications;

const NotificationItem = () => {
  const [read, setRead] = useState(false);

   return (
    <div
      className={`relative w-full px-4 py-2 rounded-[12px] ${read ? 'bg-[#f5f5f5]' : 'bg-gray-200'} hover:shadow-lg`}
      onMouseEnter={() => setRead(true)}
    >
      <div className="flex items-center gap-x-4">
        <div className="flex items-center justify-center w-10 h-10 bg-yellow-300 rounded-full">
          <IoIosNotificationsOutline size={24} />
        </div>
        <div className="flex-1">
          <h2 className="font-semibold text-[15px]">Notification Title</h2>
          <p className="text-[12px] mb-2">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta, sint!
          </p>
          <div className="flex gap-4 items-center">
            <button className="bg-gray-400 py-1 px-3 text-white rounded-[24px] text-[13px] hover:bg-green-500">
              Accept
            </button>
            <button className="bg-gray-400 py-1 px-3 text-white rounded-[24px] text-[13px] hover:bg-red-500">
              Decline
            </button>
          </div>
        </div>
      </div>
      <div className="absolute top-2 right-2 mt-1 mr-1">
        <div className="w-2 h-2 bg-purple-500 rounded-full"></div>
      </div>
      <p className="text-right text-[11px] font-medium">- 2 seconds ago</p>
    </div>
  );
};